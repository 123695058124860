/*
 * 업무구분 : 고객카드
 * 화 면 명 : MSPCM270M
 * 화면설명 : 고객카드
 * 작 성 일 : 2023.02.16
 * 작 성 자 : sh.park2022
 *
 * 고객카드 진입시, Store 고객정보 Clear
 * 타메뉴 진입시, 고객카드 정보 Store 저장
 */
 <template>
  <ur-page-container ref="page" class="msp" type="subpage" :show-title="false" title="고객카드" :topButton="true">
    <ur-box-container direction="column" alignV="start" class="msp-ap-wrap">
      <div class="ns-sliding-topbar on">
        <mo-collapsing-header-view headerColor="#fff" :fixedDiff="26" ref="headerview">

          <!-- 헤더 타이틀 영역 -->
          <template #fixed="{scrollRate}">
            <mo-top-bar color="primary" :scroll-target="lv_HeaderviewScrollCompID" scroll-shadow>
              <mo-button class="btn_popup_back" @click="fn_DevicBackBtnHandler"></mo-button>
              <div slot="nav" class="icon-wrapper" @click="fn_DevicBackBtnHandler">
                <mo-icon color="white" icon-size="36px" borderless>drawer</mo-icon>
                
              </div>
              <div class="ui-gnb-title__main">{{ scrollRate > 0.5 ? '고객카드' : '고객카드' }}</div>
              <div slot="action" class="icon-wrapper">
                <mo-button shape="border" class="ns-btn-round btn-title-style-2 blue-fill" @click="fn_OpenMSPCM300P()" :disabled="!fn_CmptdChkCnslt">수정</mo-button>
                <mo-icon icon-size="30px" type="picto" @click="fn_ActionSlotOpen()">more</mo-icon>
                <mo-icon icon="msp-hamburger" class="fs26rem" @click="fn_openMenu()"/>
              </div>
            </mo-top-bar>
          </template>

          <!-- collapsing 영역 -->
          <template #collapsing>
            <div class="text_hidden">hidden</div>
            <ur-box-container v-if="!$bizUtil.isEmpty(lv_CustCardInfoVO.custNm)" alignV="start" componentid="" direction="column" class="ns-check-list pt10">
              <mo-list-item class="bdBN">
                <div class="list-item__contents pt5 pb10">
                  <div class="list-item__contents__title fexTy3 mb0">
                    <!-- 고객명 -->
                    <span class="name fs18rem fexInt fexTy5 w100-80">
                      {{ lv_CustCardInfoVO.custNm }}
                    </span>
                    <!-- 뱃지 -->
                    <mo-badge class="badge-box sm lightblue3" text="" shape="status">{{ fn_CmptdCustScNm }}</mo-badge>
                  </div>
                  <div class="list-item__contents__info pt10 pb0" v-if="!lv_IsLoading">
                    <span class="crTy-bk1">{{ lv_CustCardInfoVO.birthDay }}</span><em>|</em>
                    <span class="crTy-bk1">{{ lv_CustCardInfoVO.sxdsNm }}</span><em>|</em>
                    <span class="crTy-bk1">{{ lv_CustCardInfoVO.celno }}</span>
                  </div>
                </div>
              </mo-list-item>
            </ur-box-container>
          </template>

          <template #sticky>
            <div class="sd-b-Ty1">
              <!-- 메인 카테고리 출력 -->
              <mo-tab-box :default-idx="menuLclsfIdx" class="ns-move-tab-box" ref="tabBox"><!--primary-->
                <!-- tab id loop -->
                <template v-for="item in lv_MenuLclsfList"> 
                  <mo-tab-label :idx="item.idx.toString()" :key="item.menuId" @click="fn_MenuLclsfClick(item)">
                    {{ item.menuNm }}
                  </mo-tab-label>
                </template>
                <!-- tab id loop //-->
              </mo-tab-box>
              <!-- 메인 카테고리 출력 //-->
              <!-- 서브카테고리 출력 -->
              <template v-if="fn_MenuSclsfList.length > 1">
                <ur-box-container alignV="start" componentid="ur_box_container_111" direction="column">
                  <ur-tabs-container componentid="ur_tabs_container_110" :tabId="lv_TabIdValue" class="ns-tabs-box2 no-tab" ref="tabsContainer" @tab-changed="fn_MenuSclsfClick"><!--@tab-changed="fn_Dept3Click"-->
                    <template v-for="item in fn_MenuSclsfList"> 
                      <ur-tab-item-container :componentid="item.menuId" :title="item.menuNm" :tabId="item.viewId" :key="item.menuId">
                        <!-- 서브 카테고리 Contents 영역 -->
                      </ur-tab-item-container>
                    </template>
                  </ur-tabs-container>
                </ur-box-container>
              </template>
              <!-- 서브카테고리 출력 //-->
            </div>
          </template>

          <!-- scroll 영역 -->
          <template #scroll>
            <div ref="refScrollArea">
              <ur-box-container v-show="fn_ShowAttTip" ref="refAttTooltip" alignV="start" componentid="ur_box_container_001" direction="column" class="msp sales-sys box-shadow__line">
                <ur-box-container alignV="start" componentid="" direction="column" class="con-area pt30 pb10">
                  <div class="attention-tip"> 
                    <mo-icon icon="msp-attention" class="att-icon">msp-attention</mo-icon>
                    <p class="att-txt ml5 fs16rem">
                      고객님의 데이터를 분석한 추천정보가 있어요. 확인하러 가볼까요? <b @click="fn_MoveCustPrpnsInfo"><u>확인</u></b>
                    </p>
                    <mo-icon icon="msp-close-bold" class="att-btn-close" @click="showAttTip = false">msp-close-bold</mo-icon>
                  </div>
                </ur-box-container>
              </ur-box-container>

              <MSPCM271D v-show="fn_ShowCustTab" ref="refMSPCM271D" :pCustInfo="pCM271D" @affordanceCntrol='fn_affordanceReset'/><!-- 고객정보 - 기본정보 -->
              <MSPCM274D v-show="fn_ShowCustTab" ref="refMSPCM274D" :pCustInfo="pCM274D" @callInit="fn_Init"/><!-- 고객정보 - 세대관계정보 -->
              <MSPCM275D v-show="fn_ShowCustTab" ref="refMSPCM275D" :pCustInfo="pCM275D" :class="{ mb40: !lv_CustPrpnsSVO.upselGrdNm }" /><!-- 고객정보 - 정보활용동의/기타정보 -->
              <MSPCM291D v-show="fn_ShowCustTab && lv_CustPrpnsSVO.upselGrdNm" ref="refMSPCM291D" :class="{ mb40: !isCnfAtpnt }" :pCustInfo="pCM291D" @confirm="fn_OpenCustPrpns" /><!-- 고객정보 - 추천정보 -->
              <MSPCM292D v-show="fn_ShowCustTab && isCnfAtpnt" ref="refMSPCM292D" :pCustInfo="pCM292D" /><!-- 고객정보 - 고객성향 -->
              <MSPCM276D v-show="fn_ShowContTab" :pCustInfo="pCM276D" /><!-- 보유계약 -->
              <MSPCM278D v-show="fn_ShowActTab" ref="refMSPCM278D" :pCustInfo="pCM278D" /><!-- 활동 - 최근활동 -->
              <!-- <MSPCM279D v-show="lv_ViewId === 'MSPCM279D'" :pCustInfo="pCM279D" /> --><!-- 활동 - 동의현황 -->
              <MSPCM282D v-show="fn_ShowActTab" ref="refMSPCM282D" :pCustInfo="pCM282D" /><!-- 활동 - 컨설팅 -->
              <MSPCM281D v-show="fn_ShowActTab" ref="refMSPCM281D" :pCustInfo="pCM281D" @affordanceCntrol='fn_affordanceReset'/><!-- 활동 - 최근 설계 상품 -->
              <MSPCM285D v-show="lv_ViewId === 'MSPCM285D'" :pCustInfo="pCM285D" /><!-- 터치이력 - 계약사항변경 -->
              <MSPCM286D v-show="lv_ViewId === 'MSPCM286D'" :pCustInfo="pCM286D" :rsltBasSVO="lv_RsltBasSVO"  /><!-- 터치이력 - FC터치 -->
              <MSPCM287D v-show="lv_ViewId === 'MSPCM287D'" :pCustInfo="pCM287D" /><!-- 터치이력 - 캠페인 -->
              <MSPCM288D v-show="lv_ViewId === 'MSPCM288D'" :pCustInfo="pCM288D" /><!-- 터치이력 - 접촉 -->
              <MSPCM289D v-show="lv_ViewId === 'MSPCM289D'" :pCustInfo="pCM289D" /><!-- 터치이력 - 지급 -->
            </div>
          </template>
        </mo-collapsing-header-view>
      </div>
    </ur-box-container>
    <!-- 더보기 -->
    <mo-bottom-sheet ref="refMoreList" class="ns-bottom-sheet" :close-btn="true">
      <div class="content-area pal0 pb30">
        <ur-box-container alignV="start" componentid="" direction="column" class="btn-list-type">
          <mo-list :list-data="lv_TotMenus">
            <template #list-item="{ item }">
              <mo-list-item>
                <mo-button @click="fn_SetMenu(item.viewId),fn_ActionSlotClose()" class="ns-btn-check">{{item.menuNm}}<mo-icon icon="msp-check-bold"/></mo-button>
              </mo-list-item>
            </template>
          </mo-list>
        </ur-box-container>
      </div>
    </mo-bottom-sheet>

    <div class="chevron_area" v-show="isShowChevron">
      <div class="chevron" @click="fn_ClickChevron()">
        <a href="#">
          <!-- <mo-icon icon="more_view" /> -->
          <i></i>
          <span>더보기</span>
        </a>
      </div>
    </div> 
  </ur-page-container>
</template>

<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
***********************************************************************************/
import { createScrollAnimateInstance } from '@/utils/scroll/scroll-animate' // error
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import CmConstants from '@/config/constants/cmConstants'
import Msg from '@/systems/webkit/msg/msg'
import moment from 'moment'

import MSPCM271D from '@/ui/cm/MSPCM271D.vue' // 고객정보 - 기본정보
import MSPCM274D from '@/ui/cm/MSPCM274D.vue' // 고객정보 - 세대관계정보
import MSPCM275D from '@/ui/cm/MSPCM275D.vue' // 고객정보 - 정보활용동의/기타정보
import MSPCM291D from '~/src/ui/cm/MSPCM291D.vue' // 고객정보 - 추천정보
import MSPCM292D from '~/src/ui/cm/MSPCM292D.vue' // 고객정보 - 고객성향
import MSPCM276D from '@/ui/cm/MSPCM276D.vue' // 보유계약
import MSPCM278D from '@/ui/cm/MSPCM278D.vue' // 활동 - 최근활동
import MSPCM279D from '@/ui/cm/MSPCM279D.vue' // 활동 - 동의현황
import MSPCM281D from '@/ui/cm/MSPCM281D.vue' // 활동 - 최근 설계 상품
import MSPCM282D from '@/ui/cm/MSPCM282D.vue' // 활동 - 컨설팅
import MSPCM285D from '@/ui/cm/MSPCM285D.vue' // 터치이력 - 계약사항변경
import MSPCM286D from '@/ui/cm/MSPCM286D.vue' // 터치이력 - FC터치
import MSPCM287D from '@/ui/cm/MSPCM287D.vue' // 터치이력 - 캠페인
import MSPCM288D from '@/ui/cm/MSPCM288D.vue' // 터치이력 - 접촉
import MSPCM289D from '@/ui/cm/MSPCM289D.vue' // 터치이력 - 지급

import MSPCM300P from '@/ui/cm/MSPCM300P.vue' // 고객카드 수정

import piCommonUtil from '@/ui/pi/common/piCommonUtil'
export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역
  ***********************************************************************************/
  // 화면명
  name: 'MSPCM270M', 
  // 화면ID
  screenId: 'MSPCM270M',
  // 컴포넌트 정의
  components: {
    MSPCM271D,MSPCM274D,MSPCM275D,MSPCM276D,MSPCM278D,
    MSPCM279D,MSPCM281D,MSPCM282D,MSPCM285D,MSPCM286D,
    MSPCM287D,MSPCM288D,MSPCM289D, MSPCM291D, MSPCM292D
  },
  /***********************************************************************************
   * 화면 UI Property 정의
  ***********************************************************************************/
  props: {
    viewId: String,
    callerId: String,
    chnlCustId: String,
    cnsltNo: String
  },
  /***********************************************************************************
   * 화면 전역 변수 선언 영역
  ***********************************************************************************/
  data () {
    return {
      isShowChevron:true,             // 하단 더보기 버튼 노출여부
      isCnfAtpnt: false,  // 고객성향 유의사항 확인 여부
      showAttTip: true,   // 추천정보 이동 툴팁 여부

      // 사용자 정보
      lv_UserInfo: {},

      // 서비스 통신 파라미터
      lv_Param: {
        chnlCustId: '', // 채널고객 ID
        cnsltNo: '',    // 컨설턴트번호
        whlInqrYn: 'Y', // 전체조회여부 - 디폴트
        conctScCd: '1'  // 접속구분코드 5 (1.내부(INTRANET) 2.외부(INTERNET) ) (As-is) MobileHeaderManager.getCommVO().conctScCd;
      },

      // 송신화면 ID
      lv_CallerId: '',
      // 수신디비전 ID
      lv_ViewId: '',
      // 스크롤 대상 컴포넌트 ID
      lv_HeaderviewScrollCompID: '',
      // 대분류 메뉴 인덱스
      menuLclsfIdx: '1',
      // 대분류 메뉴 리스트
      lv_MenuLclsfList: CmConstants.CM_HOME_MENU_LIST,
      // 소분류 메뉴 리스트
      lv_MenuSclsfList: [],
      // 대분류 메뉴 선택된탭 Object
      lv_MenuLclsfObj: {},
      // 소분류 메뉴 선택된탭 Object
      lv_MenuSclsfObj: {},
      // 소분류 메뉴 탭 ID
      lv_TabIdValue: '',
      // 고객요약정보
      lv_CmCustCardBasInfoSVO: {},
      // 고객요약정보
      lv_CustCardInfoVO: {},
      // 동의정보 조회
      lv_CustPrcusCnsntSVO: {},
      // 고객성향
      lv_CustPrpnsSVO: {},
      // 인별소비금액
      lv_CustPrpnsCnsmDtlSVO: {},
      // 고객상세인적사항 정보
      lv_RsltBasSVO: {},
      // 최근가입설계 정보
      lv_RntEntplInfoSVO: [],
      // 보유계약 리스트
      lv_ContList: [],
      // 최근 활동 리스트
      lv_FCTuchSVO: [],
      // 고객 이벤트 리스트
      lv_MemrdChnlCustMoblVO: [],
      // 고객 최근 가입설계 정보 (컨설팅)
      lv_CustRntEntplSVO: {},
      // 계약사항변경 리스트(터치이력-계약사항변경)
      lv_RsltSubSVO: [],
      // FC터치(터치이력-FC터치)
      lv_FCTuchListSVO: {},
      // 캠페인 정보 목록 (터치이력-캠페인)
      lv_CmpgnListSVO: [],
      // 고객접촉 정보 목록 (터치이력-접촉)
      lv_CntcListSVO: [],
      // 고객지급이력 정보 목록 (터치이력-지급)
      lv_CmpymListSVO: [],
      // 채널고객 ID
      lv_ChnlCustId: '',
      // 컨설턴트번호
      lv_CnsltNo: '',
      // 서버일자(오늘) 처리 전용
      lv_Today: '',
      // 서비스 호출중 여부
      lv_IsLoading: false,
      // 지금정보동의 정보
      lv_PymBrkdnCnsntListDto: [],

      /***************** 디비전 Object 셋팅 *****************/
      pCM271D: { // 디비전 전달 Object (MSPCM271D)
        lv_RsltBasSVO: {}
      },
      pCM274D: { // 디비전 전달 Object (MSPCM274D)
        lv_RsltBasSVO: {}
      },
      pCM275D: { // 디비전 전달 Object (MSPCM275D)
        lv_RsltBasSVO: {}, // 고객상세인적사항 정보
        lv_CustPrcusCnsntSVO: {}, // 동의정보
        lv_PymBrkdnCnsntListDto: [] //지금정보동의 정보
      },
      pCM276D: { // 디비전 전달 Object (MSPCM276D)
        lv_RsltBasSVO: {},
        lv_ContList: {}
      },
      pCM278D: { // 디비전 전달 Object (MSPCM278D)
        lv_CustCardInfoVO: {},
        lv_FCTuchSVO: [],
        lv_MemrdChnlCustMoblVO: [],
        lv_CustPrcusCnsntSVO: {}
      },
      pCM279D: { // 디비전 전달 Object (MSPCM279D)
        lv_RsltBasSVO: {},
        lv_CustPrcusCnsntSVO: {}
      },
      pCM281D: { // 디비전 전달 Object (MSPCM281D)
        lv_RsltBasSVO: {},
        lv_RntEntplInfoSVO: []
      },
      pCM282D: { // 디비전 전달 Object (MSPCM282D)
        lv_RsltBasSVO: {},
        lv_CustRntEntplSVO: {}
      },
      pCM285D: { // 디비전 전달 Object (MSPCM285D)
        lv_RsltSubSVO: []
      },
      pCM286D: { // 디비전 전달 Object (MSPCM286D)
        lv_FCTuchListSVO: [],
      },
      pCM287D: { // 디비전 전달 Object (MSPCM287D)
        lv_CmpgnListSVO: []
      },
      pCM288D: { // 디비전 전달 Object (MSPCM288D)
        lv_RsltBasSVO: {},
        lv_CntcListSVO: []
      },
      pCM289D: { // 디비전 전달 Object (MSPCM289D)
        lv_CmpymListSVO: []
      },
      pCM291D: { // 디비전 전달 Object (MSPCM291D)
        lv_RsltBasSVO: {},
        lv_CustPrcusCnsntSVO: {},
        lv_CustPrpnsSVO: {},
      },
      pCM292D: { // 디비전 전달 Object (MSPCM292D)
        lv_RsltBasSVO: {},
        lv_CustPrpnsSVO: {},
        lv_CustPrpnsCnsmDtlSVO: {}
      },

      lv_TotMenu: '', // 전체메뉴
      lv_TotMenus: [],
      isOpened: false,
      /*****************************************************/

      custTabList: ['MSPCM271D', 'MSPCM274D', 'MSPCM275D', 'MSPCM291D', 'MSPCM292D'],
      contTabList: ['MSPCM276D'],
      actTabList: ['MSPCM278D', 'MSPCM281D', 'MSPCM282D']
    };
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역
  ***********************************************************************************/
  beforeCreate() {
    window.vue.getStore('progress').dispatch('FULL')
  },
  created () {
    console.log('■■■■■■■ MSPCM270M ■■■■■■■')
    this.lv_UserInfo = this.getStore('userInfo').getters.getUserInfo

    // GNB 영역 Heghit 제거
    this.$BottomManager.fn_SetBottomVisible(false)
    // backButton event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)
    
    this.fn_Init()
  },
  mounted () {
    // 스크롤 애니메이션 instance 선언
    this.lv_HeaderviewScrollCompID = this.$refs.headerview.getScrollElementId()
    document.getElementById(this.lv_HeaderviewScrollCompID).addEventListener('scroll', this.scrollEvent)
    this.$refs.page.setScrollElementId(this.lv_HeaderviewScrollCompID)
    this.scrollAnimateY = createScrollAnimateInstance(document.getElementById(this.lv_HeaderviewScrollCompID), 'y')

    // cmStore 고객정보 초기화
    window.vue.getStore('cmStore').dispatch('ADD', {isClear: true})

    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog('MSPCM270M')
  },
  destroyed() {
    // backButton event 등록
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)

    // BNG 셋팅
    this.$cmUtil.setSelectedCustList(this, [])
    
    // 하단탭 더보기 상태 초기화
    window.vue.getStore('cmStore').dispatch('IS_MORE', false)

    // post 호출 전 Progress 타입 설정. 미설정시 FULL(전체화면)이 기본값
    window.vue.getStore('progress').dispatch('FULL')
  },
  /***********************************************************************************
   * watch 정의 영역
  ***********************************************************************************/
  watch: {
    // 고객 요약 정보
    lv_CustCardInfoVO() {
      this.pCM278D.lv_CustCardInfoVO = this.lv_CustCardInfoVO
    },
    // 고객 상세인적 사항 정보
    lv_RsltBasSVO() {
      this.pCM271D.lv_RsltBasSVO = this.lv_RsltBasSVO
      this.pCM274D.lv_RsltBasSVO = this.lv_RsltBasSVO
      this.pCM275D.lv_RsltBasSVO = this.lv_RsltBasSVO
      this.pCM276D.lv_RsltBasSVO = this.lv_RsltBasSVO
      this.pCM278D.lv_RsltBasSVO = this.lv_RsltBasSVO
      this.pCM279D.lv_RsltBasSVO = this.lv_RsltBasSVO
      this.pCM281D.lv_RsltBasSVO = this.lv_RsltBasSVO
      this.pCM282D.lv_RsltBasSVO = this.lv_RsltBasSVO
      this.pCM288D.lv_RsltBasSVO = this.lv_RsltBasSVO
      this.pCM291D.lv_RsltBasSVO = this.lv_RsltBasSVO
      this.pCM292D.lv_RsltBasSVO = this.lv_RsltBasSVO

      // BNG 셋팅
      let orgData = {cnslt: {}}
      orgData.cnslt.key = this.lv_CnsltNo
      this.$cmUtil.setSelectedCustList(this, [this.lv_RsltBasSVO], this.$options.screenId, orgData)
    },
    // 고객 동의정보 조회
    lv_CustPrcusCnsntSVO() {
      this.pCM275D.lv_CustPrcusCnsntSVO = this.lv_CustPrcusCnsntSVO
      this.pCM278D.lv_CustPrcusCnsntSVO = this.lv_CustPrcusCnsntSVO
      this.pCM279D.lv_CustPrcusCnsntSVO = this.lv_CustPrcusCnsntSVO
      this.pCM291D.lv_CustPrcusCnsntSVO = this.lv_CustPrcusCnsntSVO
    },
    // 고객 보유계약
    lv_ContList() {
      this.pCM276D.lv_ContList = this.lv_ContList
    },
    // 고객 최근 활동
    lv_FCTuchSVO() {
      this.pCM278D.lv_FCTuchSVO = this.lv_FCTuchSVO
    },
    // FC터치이력
    lv_FCTuchListSVO() {
      this.pCM286D.lv_FCTuchListSVO = this.lv_FCTuchListSVO
    },
    // 고객 이벤트    
    lv_MemrdChnlCustMoblVO() {
      this.pCM278D.lv_MemrdChnlCustMoblVO = this.lv_MemrdChnlCustMoblVO
    },
    // 최근 가입설계
    lv_RntEntplInfoSVO() {
      this.pCM281D.lv_RntEntplInfoSVO = this.lv_RntEntplInfoSVO
    },
    // 계약 사항 변경 이력
    lv_RsltSubSVO() {
      this.pCM285D.lv_RsltSubSVO = this.lv_RsltSubSVO
    },
    // 컨설팅일자 조회
    lv_CustRntEntplSVO() {
      this.pCM282D.lv_CustRntEntplSVO = this.lv_CustRntEntplSVO
    },
    // 캠페인이력 정보
    lv_CmpgnListSVO() {
      this.pCM287D.lv_CmpgnListSVO = this.lv_CmpgnListSVO
    },
    // 고객 접촉이력
    lv_CntcListSVO() {
      this.pCM288D.lv_CntcListSVO = this.lv_CntcListSVO
    },
    // 고객 지급이력 정보
    lv_CmpymListSVO() {
      this.pCM289D.lv_CmpymListSVO = this.lv_CmpymListSVO
    },
    // 고객성향
    lv_CustPrpnsSVO() {
      this.pCM291D.lv_CustPrpnsSVO = this.lv_CustPrpnsSVO
      this.pCM292D.lv_CustPrpnsSVO = this.lv_CustPrpnsSVO
    },
    // 소비성향상세
    lv_CustPrpnsCnsmDtlSVO() {
      this.pCM292D.lv_CustPrpnsCnsmDtlSVO = this.lv_CustPrpnsCnsmDtlSVO
    },
    //지급정보동의
    lv_PymBrkdnCnsntListDto() {
      this.pCM275D.lv_PymBrkdnCnsntListDto = this.lv_PymBrkdnCnsntListDto
    },
    // 고객성향 유의사항 확인 여부
    isCnfAtpnt(val) {
      if (!val) return

      // 고객성향 탭 v-show="true" 일 때 화면로그 적재
      console.log("■■■■■■■ MSPCM292D ■■■■■■■")
      this.$bizUtil.insSrnLogMini("MSPCM292D")
    }
  }, // Watch

  /***********************************************************************************
   * Computed 함수 정의 영역
  ***********************************************************************************/
  computed: {
    // 본인 고객 여부
    fn_CmptdChkCnslt() {
      return this.lv_RsltBasSVO.cnsltNo === this.lv_UserInfo.userId
    },
    // 태그정의 고객구분(고객유형): 본인모집, 타인모집, 관심, 임시, 가망
    fn_CmptdCustScNm() {
      const lv_Vm = this

      let custScObj = CmConstants.CUST_CNTC_INFO_CUST_SC_CD.filter(item => {
          return item.value === lv_Vm.lv_RsltBasSVO.chnlCustScCd
        })[0];

      if (!_.isEmpty(custScObj)) {
        lv_Vm.lv_RsltBasSVO.chnlCustScNm = custScObj.text
      }

      return custScObj ? custScObj.text : ''
    },
    fn_ShowAttTip() {
      return this.fn_ShowCustTab && this.showAttTip && this.lv_CustPrpnsSVO.upselGrdNm
    },
    fn_ShowCustTab() {
      return this.custTabList.includes(this.lv_ViewId);
    },
    fn_ShowContTab() {
      return this.contTabList.includes(this.lv_ViewId);
    },
    fn_ShowActTab() {
      return this.actTabList.includes(this.lv_ViewId);
    },
    fn_MenuSclsfList() {
      // 추천정보 & 고객성향 데이터 유무에 따라 메뉴 보이도록 처리
      return this.lv_MenuSclsfList.filter(menu => !((menu.viewId === 'MSPCM291D' && !this.lv_CustPrpnsSVO.upselGrdNm) || (menu.viewId === 'MSPCM292D' && !this.isCnfAtpnt)));
    }
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역
  ***********************************************************************************/
  methods: {
    /*********************************************************
     * @function fn_Init
     * @notice   화면진입시, 최초 실행 함수
     *           초기 셋팅 및 설정
     * @ServiceCall
     *           1. 고객 요약 정보 조회(fn_SelCustSmryInfo)
     *           2. 고객 상세인적 사항 정보 조회(fn_SelCustCardInfo)
     *           3. 동의정보 조회(fn_SelInfoPrcusCnsnt)
    *********************************************************/
    fn_Init (param) {
      const lv_Vm = this
      let routeParam = this.$route.params

      // router로 전달받은 수신디비전
      if (!_.isEmpty(param) && !_.isEmpty(param.custInfo)) {
        // 고객카드수정-탭 동일화 처리
        this.lv_ViewId = param.custInfo.updtFlag === 2 ? 'MSPCM274D' : 'MSPCM271D'
      } else {
        this.lv_ViewId = routeParam.viewId ? routeParam.viewId : this.lv_MenuLclsfList[0].subList[0].viewId;
      }

      // router로 전달받은 송신화면
      this.lv_CallerId = routeParam.callerId ? routeParam.callerId : 'MSPCM115M'

      if (!_.isEmpty(param) && param.callInit === 'Y') {
        this.lv_ChnlCustId = param.custInfo.chnlCustId
        this.lv_CnsltNo = param.custInfo.cnsltNo
      } else {
        // 세대조정-callInit
        if (!_.isEmpty(routeParam)) {
          // router로 전달받은 채널고객ID
          this.lv_ChnlCustId = routeParam.chnlCustId ? routeParam.chnlCustId : ''
          // router로 전달받은 컨설턴트번호
          this.lv_CnsltNo = routeParam.cnsltNo

          window.vue.getStore('cmStore').dispatch('PRE_SRN_OBJ_CM050M', routeParam)
        } else {
          let custInfo = window.vue.getStore('cmStore').getters.getState.custInfo

          if (!_.isEmpty(custInfo)) {
            this.lv_ChnlCustId = custInfo.chnlCustId
            this.lv_CnsltNo = custInfo.cnsltNo
          }
        }
      }

      this.lv_Param.chnlCustId = this.lv_ChnlCustId
      this.lv_Param.cnsltNo = this.lv_CnsltNo
      // 선심사 및 영업시스템 고도화 PJT 미반영으로 주석처리
      // this.fn_GetCnsntInfo()
      // 채널고객ID없을경우, 이전 화면으로 전환
      if (this.$bizUtil.isEmpty(this.lv_ChnlCustId)) {
        this.$router.go(-1)
        return
      }

      // 탭메뉴 세팅
      this.fn_MenuSclsfClick(this.lv_ViewId)

      // 오늘날짜
      this.$commonUtil.getServerTime()
        .then(res => {
          lv_Vm.lv_Today = moment(res).format('YYYY-MM-DD')
        });
        
      // 고객 요약 정보 조회
      lv_Vm.fn_SelCustSmryInfo()
    },
    /*********************************************************
     * @function fn_OpenMSPCM300P
     * @name     고객카드_팝업_호출
     * @notice   고객카드 수정 팝업 Open
     *           수정시, 고객정보 재조회
    *********************************************************/
    fn_OpenMSPCM300P () {
      const lv_Vm = this
      let deltDisable = !this.fn_CmptdChkCnslt
      let pViewId = this.lv_ViewId === 'MSPCM274D' ? 'MSPCM303D' : 'MSPCM301D'
      
      let popup = this.$bottomModal.open(MSPCM300P, {
        properties: {
          pChnlCustId: this.lv_RsltBasSVO.chnlCustId,
          pCnsltNo: this.lv_RsltBasSVO.cnsltNo,
          viewId: pViewId,
          pDeltDisable: deltDisable
        },
        listeners: {
          onPopupConfirm: (auth, rtnObj) => {
            this.$bottomModal.close(popup)

            if (auth === 'U') {
              // 고객정보 - 기본정보 재조회
              lv_Vm.fn_Init(rtnObj)
            } else if (auth === 'D') {
              // 진입 전 화면으로 전환
              this.$router.push({ name: lv_Vm.lv_CallerId, params: {callerId: this.$options.screenId, chnlCustId: this.lv_RsltBasSVO.chnlCustId, auth: 'D'} })
            }
          },
          onPopupClose: () => {
            this.$bottomModal.close(popup)
          }
        }
      },
      {
        properties: {
          noHeader: true
        }
      });
      
    },
    /*********************************************************
     * @function fn_MenuLclsfClick
     * @name     대분류_클릭_이벤트
     * @param item 클릭한 대분류 메뉴(1dept)
    *********************************************************/
    fn_MenuLclsfClick (item) {
      this.lv_ViewId = item.viewId
	  //대분류 재선택 시, affordance 버튼 노출(2024-01-12 선심사 및 영업시스템 고도화 PJT)
      if(item.viewId === "MSPCM271D" || item.viewId === "MSPCM278D") {
        this.isShowChevron = true
      } else {
        this.isShowChevron = false
      }
      
      this.fn_SetMenu(item.viewId)

      this.fn_CallServiceMenu(item)
    },
    
    /*********************************************************
     * @function fn_MenuSclsfClick
     * @name     소분류_클릭_이벤트
     * @param item 클릭한 소분류 메뉴 메뉴(2dept)
    *********************************************************/
    fn_MenuSclsfClick (tabId) {
      this.lv_ViewId = tabId
      this.fn_SetMenu(tabId)

      this.fn_CallServiceMenuSub(tabId)
    },
    /*********************************************************
     * @function fn_ActionSlotOpen
     * @notice   젠체메뉴 노출
    *********************************************************/
    fn_ActionSlotOpen () {
      this.$refs.refMoreList.open()
    },
    /*********************************************************
     * @function fn_ActionSlotClose
     * @notice   젠체메뉴 숨기
    *********************************************************/
    fn_ActionSlotClose () {
      this.$refs.refMoreList.close()
    },
    /*********************************************************
     * @function fn_SetMenu
     * @notice   대분류/소분류 메뉴 선택시, 메뉴 셋팅 함수
     * @param viewId 선택 메뉴 ID 
    *********************************************************/
    fn_SetMenu (viewId) {
      const lv_Vm = this

      this.lv_ViewId = viewId

      for (let i = 0; i < lv_Vm.lv_MenuLclsfList.length; i++) {
        for (let j = 0; j < lv_Vm.lv_MenuLclsfList[i].subList.length; j++) {
          if(lv_Vm.isOpened === false){
            lv_Vm.lv_MenuLclsfList[i].subList[j].value = lv_Vm.lv_MenuLclsfList[i].subList[j].viewId
            lv_Vm.lv_MenuLclsfList[i].subList[j].text = lv_Vm.lv_MenuLclsfList[i].subList[j].menuNm

            lv_Vm.lv_TotMenus.push(lv_Vm.lv_MenuLclsfList[i].subList[j])
          }
          

          if (lv_Vm.lv_MenuLclsfList[i].subList[j].viewId === lv_Vm.lv_ViewId) {
            lv_Vm.lv_MenuLclsfObj = lv_Vm.lv_MenuLclsfList[i] // 대분류 메뉴
            lv_Vm.lv_MenuSclsfObj = lv_Vm.lv_MenuLclsfList[i].subList[j] // 소분류 메뉴
            lv_Vm.lv_MenuSclsfList = lv_Vm.lv_MenuLclsfList[i].subList   // 소분류 메뉴 리스트
          }
        } // for j
      } // for i
      lv_Vm.isOpened = true

      // 대분류 메뉴 강제 선택
      for (let k = 0; k < this.lv_MenuLclsfList.length; k++) {
        // 선택한 대분류 메뉴로 강제 선택
        if (this.lv_MenuLclsfList[k].menuId === this.lv_MenuLclsfObj.menuId) {
          this.menuLclsfIdx = this.lv_MenuLclsfList[k].idx.toString()
          break
        }
      } // for k
      
      this.lv_TabIdValue = lv_Vm.lv_MenuSclsfObj.viewId
      // 소분류 메뉴 강제선택은 카테고리 코드로 el에 세팅
      setTimeout(() => {
        // UI_DEV 수정되면 타임아웃 삭제
        if (lv_Vm.lv_MenuSclsfList.length > 1) {
          lv_Vm.$refs.tabsContainer.toggleTab(lv_Vm.lv_MenuSclsfObj.viewId)
        } else {
          // 보유계약 소분류 메뉴가 없어 서비스 강제 호출
          if (lv_Vm.lv_MenuSclsfObj.viewId === 'MSPCM276D') {
            lv_Vm.fn_SelListCont()
          }
        }
        this.$refs.tabBox.setIdx(this.menuLclsfIdx)
        this.lv_TotMenu = lv_Vm.lv_MenuSclsfObj.value
      });

    },

    /*********************************************************
     * @function fn_ClickBtnPrevious
     * @notice   이전버튼 클릭 이벤트
    *********************************************************/
    fn_ClickBtnPrevious () {
      this.$router.push({ name: this.lv_CallerId })
    },
    /*********************************************************
     * @function fn_SelCustSmryInfo
     * @notice   고객 요약 정보 조회
    *********************************************************/
    fn_SelCustSmryInfo () {
      const lv_Vm = this
      if(this.lv_IsLoading) return // 서비스 중복 호출 방지
      
      let pParams = this.lv_Param

      this.lv_IsLoading = true
      const trnstId = 'txTSSCM31S1'
      const auth = 'S'
      // 1. 고객 요약정보 단건 조회
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(res => {
          // 서버 데이터 가져오기
          if (!_.isEmpty(res.body)) {
            // 고객요약정보 데이터 저장
            Object.assign(lv_Vm.lv_CmCustCardBasInfoSVO, res.body.cMCustCardBasInfoSVO)
            lv_Vm.lv_CustCardInfoVO = res.body.cMCustCardBasInfoSVO

            let mkTelBackNo = ''
            if (!this.$bizUtil.isEmpty(lv_Vm.lv_CmCustCardBasInfoSVO.celno)) {
              lv_Vm.lv_CustCardInfoVO.celno = '[핸] ' + lv_Vm.lv_CmCustCardBasInfoSVO.celno // 핸드폰번호
              lv_Vm.lv_CustCardInfoVO.custCelno = lv_Vm.lv_CmCustCardBasInfoSVO.celno
              mkTelBackNo = lv_Vm.lv_CmCustCardBasInfoSVO.celno.substr(-4)
              lv_Vm.lv_CustCardInfoVO.mkCelno = '[핸] ' + lv_Vm.lv_CmCustCardBasInfoSVO.celno.replace(mkTelBackNo, '****')
            } else if (!this.$bizUtil.isEmpty(lv_Vm.lv_CmCustCardBasInfoSVO.homTelno)) {
              lv_Vm.lv_CustCardInfoVO.celno = '[자] ' + lv_Vm.lv_CmCustCardBasInfoSVO.homTelno // 자택번호
              mkTelBackNo = lv_Vm.lv_CmCustCardBasInfoSVO.homTelno.substr(-4)
              lv_Vm.lv_CustCardInfoVO.mkCelno = '[자] ' + lv_Vm.lv_CmCustCardBasInfoSVO.homTelno.replace(mkTelBackNo, '****')
            } else if (!this.$bizUtil.isEmpty(lv_Vm.lv_CmCustCardBasInfoSVO.jobpTelno)) {
              lv_Vm.lv_CustCardInfoVO.celno = '[직] ' + lv_Vm.lv_CmCustCardBasInfoSVO.jobpTelno // 직장번호
              mkTelBackNo = lv_Vm.lv_CmCustCardBasInfoSVO.jobpTelno.substr(-4)
              lv_Vm.lv_CustCardInfoVO.mkCelno = '[직] ' + lv_Vm.lv_CmCustCardBasInfoSVO.jobpTelno.replace(mkTelBackNo, '****')
            }

            lv_Vm.lv_CustCardInfoVO.custNm = lv_Vm.lv_CmCustCardBasInfoSVO.custNm // 고객명

            lv_Vm.lv_CustCardInfoVO.sxdsCd = lv_Vm.lv_CmCustCardBasInfoSVO.knb.substring(6, 7) // 성별코드
            lv_Vm.lv_CustCardInfoVO.sxdsNm = lv_Vm.$cmUtil.getSxdsNmByRrn(lv_Vm.lv_CustCardInfoVO.knb)

            // 이벤트 데이터 바인딩
            if (!_.isEmpty(res.body.iCMemrdChnlCustMoblVO)) {
              lv_Vm.lv_MemrdChnlCustMoblVO = res.body.iCMemrdChnlCustMoblVO
            }

            // // 최근활동 데이터 바인딩
            if (!_.isEmpty(res.body.cMFCTuchSVO)) {
              lv_Vm.lv_FCTuchSVO = res.body.cMFCTuchSVO
            }

            // 2. 고객 상세인적 사항 정보 조회
            lv_Vm.fn_SelCustCardInfo()
          } else {
            lv_Vm.lv_IsLoading = false
          }
        })
        .catch(error => {
          lv_Vm.lv_IsLoading = false
          window.vue.error(error)
        });
        
    },
    /*********************************************************
     * @function fn_SelCustCardInfo
     * @notice   고객 상세인적 사항 정보 조회
    *********************************************************/
    fn_SelCustCardInfo () {
      const lv_Vm = this

      let pParams = this.lv_Param

      const trnstId = 'txTSSCM11S1'
      const auth = 'S'
      // 고객상세인적사항 정보 조회
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(res => {
          // 서비스 기본 후처리 (서비스 호출 결과(body)가 있을 경우)
          if (!_.isEmpty(res.body)) {
            if (lv_Vm.$commonUtil.isSuccess(res)) {
              // 서버 데이터 가져오기
              res.body.knbFrno = res.body.knb ? res.body.knb.substr(0,6) : ''

              res.body.iCHoshInfoListInqrVO.forEach(item => {
                if (!this.$bizUtil.isEmpty(item.knb)) {
                  item.sxdsCd = item.knb.substr(6,1) % 2 === 1 ? '1' : '2'
                }
              })

              lv_Vm.lv_RsltBasSVO = res.body

              // 생년월일
              let fullBirthDay = lv_Vm.$cmUtil.getFullBirthYear(lv_Vm.lv_RsltBasSVO.knb).substr(0,8)
              lv_Vm.lv_CustCardInfoVO.birthDay = lv_Vm.$commonUtil.dateDashFormat(fullBirthDay)
            } else {
              // 고객카드 정보가 없거나 변경, 삭제된 경우
            }
          }

          // 3. 고객 상세인적 사항 정보 조회
          lv_Vm.fn_SelInfoPrcusCnsnt()
        })
        .catch(error => {
          lv_Vm.lv_IsLoading = false
          window.vue.error(error)
        });

    },
    /*********************************************************
     * @function fn_SelInfoPrcusCnsnt
     * @notice   고객 동의 정보 조회
    *********************************************************/
    fn_SelInfoPrcusCnsnt () {
      const lv_Vm = this

      let pParams = this.lv_Param

      const trnstId = 'txTSSCM31SD'
      const auth = 'S'
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(res => {
          // 서버 데이터 가져오기
          if (!_.isEmpty(res.body)) {
            // 동의정보 데이터 바인딩
            lv_Vm.lv_CustPrcusCnsntSVO = res.body
          }

          lv_Vm.fn_SelCustPrpnsMainInfo()
        })
        .catch(error => {
          lv_Vm.lv_IsLoading = false;
          window.vue.error(error)
        })

    },
    /*********************************************************
     * @function fn_SelCustPrpnsMainInfo
     * @notice   고객성향정보 조회
    *********************************************************/
    fn_SelCustPrpnsMainInfo () {
      const lv_Vm = this

      let pParams = {
        bpId: lv_Vm.lv_RsltBasSVO.custId // 고객ID
      }

      const trnstId = 'txTSSCM15S1'
      const auth = 'S'
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(res => {
          if (!_.isEmpty(res.body)) {
            lv_Vm.lv_CustPrpnsSVO = res.body
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', res.msgComm.msgDesc)
          }
        })
        .catch(error => {
          window.vue.error(error)
        })
        .finally(() => {
          lv_Vm.lv_IsLoading = false
        })
    },
    /*********************************************************
     * @function fn_OpenCustPrpns
     * @name     고객성향_서브메뉴_호출
     * @notice   고객성향 서브메뉴 Open
    *********************************************************/
    fn_OpenCustPrpns() {
      const lv_Vm = this

      let pParams = {
        bpId: lv_Vm.lv_RsltBasSVO.custId, // 고객ID
        indclNm: '' // 업종 (전체 조회 시 공백 or 필드 삭제)
      }

      lv_Vm.lv_IsLoading = true;
      
      const trnstId = 'txTSSCM15S2'
      const auth = 'S'
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(res => {
          if (!_.isEmpty(res.body) && res.body.iccustPrpnsCnsmDtlVO.length) {
            const whlItem = res.body.iccustPrpnsCnsmDtlVO.filter(item => item.indclNm === '전체')[0];
            if (!whlItem) {
              lv_Vm.getStore('confirm').dispatch('ADD', '"전체" 업종이 존재하지 않습니다.')
            }
            
            // 전체, 선호업종 1~3등까지 필터 (영업활용업종 포함)
            lv_Vm.lv_CustPrpnsCnsmDtlSVO = [
              whlItem, 
              ...res.body.iccustPrpnsCnsmDtlVO
                .filter(item => ['1', '2', '3'].includes(item.whlUtilzAmtRnk) || ['1', '2', '3'].includes(item.salesUtilzAmtRnk))
                .map(item => {
                  item.peerDmt6CardUtilzAmtRto = item.peerDmt6CardUtilzVal01 / whlItem.dmt6AvrCardUtilzVal * 100;
                  return item;
                })
            ];
            lv_Vm.isCnfAtpnt = true
          }
        })
        .catch(error => {
          window.vue.error(error)
        })
        .finally(() => {
          lv_Vm.lv_IsLoading = false;

          // 고객성향 데이터 없어도 소비관심사 or 투자성향 보유시 고객성향 화면 보여야함
          if (!lv_Vm.isCnfAtpn)
            lv_Vm.isCnfAtpnt = lv_Vm.lv_CustPrpnsSVO.prfrCnsmIndclNm01.trim() || lv_Vm.lv_CustPrpnsSVO.ssfnIvtPrpnsNm.trim();

          if (lv_Vm.isCnfAtpnt) {
            lv_Vm.$nextTick(() => {
              const tabId = 'MSPCM292D'
              lv_Vm.$refs.tabsContainer.selectedTabId = tabId
              lv_Vm.fn_CallServiceMenuSub(tabId)
            })
          }
        });
    },
    /*********************************************************
     * @function fn_CallServiceMenu
     * @name     대분류_탭별_서비스_호출_핸들러
     * @notice   선택한 탭별로, 서비스 호출
    *********************************************************/
    fn_CallServiceMenu (memu) {
      let viewId = memu ? memu.subList[0].viewId : ''

      switch (viewId) {
        case 'MSPCM271D': // 고객정보
          this.fn_SelCustSmryInfo()
          break;
        case 'MSPCM276D': // 보유계약
          this.fn_SelListCont()
          break;
        case 'MSPCM278D': // 활동
          this.fn_SelListRntEntpl()
          break;
        case 'MSPCM285D': // 터치이력
          this.fn_SelListContChgHstr()
          break;
      }
    },
    /*********************************************************
     * @function fn_CallServiceMenuSub
     * @name     소분류_탭별_서비스_호출_핸들러
     * @notice   선택한 탭별로, 서비스 호출
    *********************************************************/
    fn_CallServiceMenuSub (viewId) {
      switch (viewId) {
        // case 'MSPCM281D': // 활동 - 최근 설계 상품
        //   this.fn_SelListRntEntpl()
        //   break;
        // case 'MSPCM282D': // 활동 - 컨설팅
        //   this.fn_SelMaxCnsltDt()
        //   break;
        case 'MSPCM285D': // 터치이력 - 계약사항변경
          this.fn_SelListContChgHstr()
          break;
        case 'MSPCM286D': // 터치이력 - FC터치
          this.fn_SelListFCTuchHstr()
          break;
        case 'MSPCM287D': // 터치이력 - 캠페인
          this.fn_CnfmCmpgnHstr()
          // this.fn_SelListCmpgnHstr()
          break;
        case 'MSPCM288D': // 터치이력 - 접촉
          this.fn_SelListCntcHstr()
          break;
        case 'MSPCM289D': // 터치이력 - 지급
          this.fn_SelListPymHstr()
          break;
      }
      
      //소분류 선택 시, 스크롤 이동(2024-01-12 선심사 및 영업시스템 고도화 PJT)
      let offsetTop = 0
      let titleHeight = 33;
      switch (viewId) {
        case 'MSPCM271D': // 고객정보 - 기본정보
          offsetTop = 0
          break;
        case 'MSPCM274D': // 고객정보 - 세대관계정보
          offsetTop = this.$refs.refMSPCM274D.$el.offsetTop - titleHeight - 130
          break;
        case 'MSPCM275D': //고객정보 - 정보활용동의/기타정보
          offsetTop = this.$refs.refMSPCM275D.$el.offsetTop - titleHeight - 130
          break;
        case 'MSPCM291D': //고객정보 - 추천정보
          offsetTop = this.$refs.refMSPCM291D.$el.offsetTop - titleHeight - 130
          break;
        case 'MSPCM292D': //고객정보 - 고객성향
          offsetTop = this.$refs.refMSPCM292D.$el.offsetTop - titleHeight - 130
          break;
        case 'MSPCM278D': // 활동 - 최근활동
          offsetTop = 0
          break;
        case 'MSPCM282D': // 활동 - 컨설팅
          offsetTop = this.$refs.refMSPCM278D.$el.scrollHeight + 90
          break;
        case 'MSPCM281D': // 활동 - 최근 설계 상품
          offsetTop = this.$refs.refMSPCM281D.$el.offsetTop - titleHeight - 120
          break;
      }

      if(this.scrollAnimateY) this.scrollAnimateY.animate(offsetTop, 700, 0, 'easeOut')
    },
    /*********************************************************
     * @function fn_SelListRntEntpl
     * @notice   최근 가입설계 조회
    *********************************************************/
    fn_SelListRntEntpl () {
      const lv_Vm = this

      let pParams = this.lv_Param

      const trnstId = 'txTSSCM31SC'
      const auth = 'S'
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(res => {
          // 서버 데이터 가져오기
          if (!_.isEmpty(res.body)) {
            lv_Vm.lv_RntEntplInfoSVO = res.body.cMCustRntEntplInfoSVO
          }

          this.fn_SelMaxCnsltDt()

        })
        .catch(error => {
          window.vue.error(error)
        });

    },
    /*********************************************************
     * @function fn_SelListCont
     * @notice   고객 보유 계약 조회
    *********************************************************/
    fn_SelListCont () {
      const lv_Vm = this

      let pParams = this.lv_Param

      const trnstId = 'txTSSCM31SE'
      const auth = 'S'
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(res => {
          if (!_.isEmpty(res.body)) {
            lv_Vm.lv_ContList = res.body.cMCustContSVO
          }
        })
        .catch(error => {
          window.vue.error(error)
        });
        
    },
    /*********************************************************
     * @function fn_SelListContChgHstr
     * @notice   계약 사항 변경 이력 조회
    *********************************************************/
    fn_SelListContChgHstr () {
      const lv_Vm = this

      let pParams = {
        cnsltNo: this.$route.params.cnsltNo,
        chnlCustId: this.$route.params.chnlCustId,
        strYmd: this.$cmUtil.dateCal(this.lv_Today, CmConstants.DATE_FACTOR_YEAR, -5), // 최대 오늘(영업일)부터 5년전
        endYmd: this.$cmUtil.replaceNaNChars(this.lv_Today) // 오늘(영업일)
      }

      const trnstId = 'txTSSCM31SB'
      const auth = 'S'
      lv_Vm.post(lv_Vm, pParams, trnstId, auth)
        .then(res => {
          if (!_.isEmpty(res.body)) {
            lv_Vm.lv_RsltSubSVO = res.body.cmcontChgSVO
          }
        })
        .catch(error => {
          window.vue.error(error)
        });

    },
    /*********************************************************
     * @function fn_SelMaxCnsltDt
     * @notice   컨설팅일자 조회
    *********************************************************/
    fn_SelMaxCnsltDt () {
      const lv_Vm = this

      let pParams = {
        chnlCustId: this.lv_RsltBasSVO.chnlCustId, // "41769252504172809102",
        cnsltNo: this.lv_RsltBasSVO.cnsltNo // "0001057465"
      }

      const trnstId = 'txTSSCM31SF'
      const auth = 'S'
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(res => {
          if (!_.isEmpty(res.body)) {
            lv_Vm.lv_CustRntEntplSVO = res.body
            // 보장분석일자 유파트너 서비스를 통해 개별 조회하기 때문에 주석 처리 - 보장분석시스템 고도화 PJT
            // lv_Vm.lv_covrAnlyYmd = res.body.covrAnlyYmd
          }
        })
        .catch(error => {
          window.vue.error(error)
        });

    },
    /*********************************************************
     * @function fn_SelListFCTuchHstr
     * @notice   FC터치이력 조회
     *  @param    cnsltNo: 컨설턴트 번호 <필수>
     *  @param    chnlCustId: 채널고객ID <필수>
     *  @param    strYmd: 조회기간 (시작일작) <옵션>
     *  @param    endYmd: 조회기간 (종료일자) <옵션>
    *********************************************************/
    fn_SelListFCTuchHstr () {
      const lv_Vm = this
      
      let pParams = {
        chnlCustId: this.lv_RsltBasSVO.chnlCustId,
        cnsltNo: this.lv_RsltBasSVO.cnsltNo,
        strYmd: this.$cmUtil.dateCal(this.lv_Today, CmConstants.DATE_FACTOR_YEAR, -5), // 최대 오늘(영업일)부터 5년전
        endYmd: this.$cmUtil.replaceNaNChars(this.lv_Today), // 오늘(영업일)
      }

      const trnstId = 'txTSSCM31S2'
      const auth = 'S'
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(res => {
          if (!_.isEmpty(res.body)) {
            lv_Vm.lv_FCTuchListSVO = res.body.cMFCTuchSVO
          }
        })
        .catch(error => {
          window.vue.error(error)
        });

    },
    /*********************************************************
     * @function fn_SelListCmpgnHstr
     * @notice   캠페인이력 정보 조회
     *  @param    cnsltNo: 컨설턴트 번호 <필수>
     *  @param    chnlCustId: 채널고객ID <필수>
     *  @param    strYmd: 조회기간 (시작일작) <옵션>
     *  @param    endYmd: 조회기간 (종료일자) <옵션>
     *  @param    cmpgnType: (캠페인유형) ['': 전체, 10: 타겟DB, 11: 제휴DB, 21: 고객사랑서비스, 22: VIP서비스, 31: my고객상담, 32: 신규상담, 33: 캠페인상담, 41: 세미나, 42: 시장개척]
    *********************************************************/
    fn_SelListCmpgnHstr () {
      const lv_Vm = this

      let pParam = {
        cnsltNo: this.lv_RsltBasSVO.cnsltNo,
        chnlCustId: this.lv_RsltBasSVO.chnlCustId,
        strYmd: this.$cmUtil.dateCal(this.lv_Today, CmConstants.DATE_FACTOR_YEAR, -5), // 최대 오늘(영업일)부터 5년전
        endYmd: this.$cmUtil.replaceNaNChars(this.lv_Today), // 오늘(영업일)
        cmpgnType: CmConstants.INIT_STRING_SPACE // (단위테스트용)
      }

      const trnstId = 'txTSSCM31S4'
      const auth = 'S'
      this.post(lv_Vm, pParam, trnstId, auth)
        .then(res => {
          if (!_.isEmpty(res.body)) {
            lv_Vm.lv_CmpgnListSVO = res.body.cmcmpgnSVO
          }
        })
        .catch(error => {
          window.vue.error(error)
        });

    },
    /*********************************************************
     * @function fn_SelListCntcHstr
     * @notice   고객접촉이력 조회
     *  @param    cnsltNo: 컨설턴트 번호 <필수>
     *  @param    chnlCustId: 채널고객ID <필수>
     *  @param    strYmd: 조회기간 (시작일작) <옵션>
     *  @param    endYmd: 조회기간 (종료일자) <옵션>
    *********************************************************/
    fn_SelListCntcHstr () {
      const lv_Vm = this

      let pParam = {
        cnsltNo: this.lv_RsltBasSVO.cnsltNo,
        chnlCustId: this.lv_RsltBasSVO.chnlCustId,
        strYmd: this.$cmUtil.dateCal(this.lv_Today, CmConstants.DATE_FACTOR_YEAR, -5), // 최대 오늘(영업일)부터 5년전
        endYmd: this.$cmUtil.replaceNaNChars(this.lv_Today) // 오늘(영업일)
      }

      const trnstId = 'txTSSCM31S5'
      const auth = 'S'
      this.post(lv_Vm, pParam, trnstId, auth)
        .then(res => {
          if (!_.isEmpty(res.body)) {
            lv_Vm.lv_CntcListSVO = res.body.cmcntcSVO
          }
        })
        .catch(error => {
          window.vue.error(error)
        });

    }, 
    /*********************************************************
     * @function fn_SelListPymHstr
     * @notice   고객 지급이력 정보 조회
     *  @param    cnsltNo: 컨설턴트 번호 <필수>
     *  @param    chnlCustId: 채널고객ID <필수>
     *  @param    strYmd: 조회기간 (시작일작) <옵션>
     *  @param    endYmd: 조회기간 (종료일자) <옵션>
    *********************************************************/
    fn_SelListPymHstr () {
      const lv_Vm = this

      let pParam = {
        cnsltNo: this.lv_RsltBasSVO.cnsltNo,
        chnlCustId: this.lv_RsltBasSVO.chnlCustId,
        strYmd: this.$cmUtil.dateCal(this.lv_Today, CmConstants.DATE_FACTOR_YEAR, -5), // 최대 오늘(영업일)부터 5년전
        endYmd: this.$cmUtil.replaceNaNChars(this.lv_Today) // 오늘(영업일)
      }

      const trnstId = 'txTSSCM31S6'
      const auth = 'S'
      this.post(lv_Vm, pParam, trnstId, auth)
        .then(res => {
          if (!_.isEmpty(res.body)) {
            lv_Vm.lv_CmpymListSVO = res.body.cmpymSVO
          }
        })
        .catch(error => {
          window.vue.error(error)
        });

    },
    /*********************************************************
     * @function fn_CnfmCmpgnHstr
     * @notice   캠페인 조회 컨펌 알럿
    *********************************************************/
    fn_CnfmCmpgnHstr () {
      const lv_Vm = this

      let alertMsg = '캠페인 이력을 불러오는데 시간이 소요될 수 있습니다.<br>조회 하시겠습니까?'

      let alertObj = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          content: alertMsg
        },
        listeners: {
          onPopupConfirm: () => {
            this.$bottomModal.close(alertObj)
            lv_Vm.fn_SelListCmpgnHstr()
          },
          onPopupClose: () => {
            this.$bottomModal.close(alertObj)
          }
        }
      })
    },
    /*********************************************************
     * @function fn_DevicBackBtnHandler
     * @notice   디바이스 Back Button 핸들러
    *********************************************************/
    fn_DevicBackBtnHandler () {
      if (window.vue.getStore('cmStore').getters.getState.isMore) {
        window.vue.getStore('cmStore').dispatch('IS_MORE', false)
        return
      }
      this.$router.go(-1)
      // this.$router.push({ name: this.lv_CallerId })
    },
    /******************************************************************************
     * @function fn_ClickChevron
     * @notice   affordance btn 클릭 시, 스크롤 이동 및 버튼 제거
     * 2024-01-12 선심사 및 영업시스템 고도화 PJ
    ******************************************************************************/
    fn_ClickChevron () {

      //어포던스 버튼 비활성화
      this.isShowChevron = false 
      
      //화면 별 스크롤 위치 설정
      let offsetTop = 0
      if(this.lv_ViewId === 'MSPCM271D') {
        offsetTop = this.$refs.refMSPCM271D.$el.scrollHeight + 130
      }
      if(this.lv_ViewId === 'MSPCM278D') {
      offsetTop = this.$refs.refMSPCM278D.$el.scrollHeight + 90
      }

      this.scrollAnimateY.animate(offsetTop, 700, 0, 'easeOut')
    },
    /******************************************************************************
     * @function scrollEvent
     * @notice   화면 진입 후, scrollEvent 발생 시, 버튼 제거
     * 2024-01-12 선심사 및 영업시스템 고도화 PJ
    ******************************************************************************/
    scrollEvent: _.debounce(function(e) {
      let target = e.target.scrollTop
      // console.log("target >> ", target, e.target, e)
      if(this.isShowChevron === true) {
        if( target > 100 ) {
          this.isShowChevron = false 
        }
      }

      let viewId = ''
      // console.log(this.$refs.tabsContainer) //tab-label-${tabId}
      // console.log("viewId >>", this.lv_MenuLclsfObj.viewId)

      //고객정보 - 스크롤 이동 시, 텝 변경
      const baseOffsetTop = this.$refs.refScrollArea.offsetTop
      if(this.lv_MenuLclsfObj.viewId === 'MSPCM271D') {
        if (target < this.$refs.refMSPCM271D.$el.offsetHeight) {
          viewId = 'MSPCM271D'
        } else if (target + baseOffsetTop < this.$refs.refMSPCM274D.$el.offsetTop + this.$refs.refMSPCM274D.$el.offsetHeight) {
          viewId = 'MSPCM274D'
        } else if (target + baseOffsetTop < this.$refs.refMSPCM275D.$el.offsetTop + this.$refs.refMSPCM275D.$el.offsetHeight) {
          viewId = 'MSPCM275D'
        } else if (target + baseOffsetTop < this.$refs.refMSPCM291D.$el.offsetTop + this.$refs.refMSPCM291D.$el.offsetHeight) {
          viewId = 'MSPCM291D'
        } else {
          viewId = 'MSPCM292D'
        }
      }
      //활동 - 스크롤 이동 시, 텝 변경
      if(this.lv_MenuLclsfObj.viewId === 'MSPCM278D') {
        if (target < this.$refs.refMSPCM278D.$el.offsetHeight - 100) {
          viewId = 'MSPCM278D'
        } else if (target + baseOffsetTop < this.$refs.refMSPCM282D.$el.offsetTop + this.$refs.refMSPCM282D.$el.offsetHeight) {
          viewId = 'MSPCM282D'
        } else {
          viewId = 'MSPCM281D'
        }
      }
      if(viewId !== ''){
        this.$refs.tabsContainer.selectedTabId = viewId
        // this.$refs.tabsContainer.toggleTab(viewId)
      }
    }, 100),
    /******************************************************************************
     * @function fn_GetCnsntInfo
     * @notice   지금정보동의 조회 서비스
     * 선심사 및 영업시스템 고도화 PJT 미반영으로 주석처리
    ******************************************************************************/    
  //   fn_GetCnsntInfo () {
  //     window.vue.getStore('progress').dispatch('SHOW')
  //     let param = {
  //       pymBrkdnCnsntListDto: {
  //         fnScCd: 'S1',                         // S1:단건조회, SA:다건조회, P1:단건 신규저장
  //         pymBrkdnCnsntList: [{                 // 보험금지급내역동의정보목록
  //           cnsltNo: this.lv_UserInfo.userEno,  // 모집컨설턴트번호
  //           insrdChnlCustId: this.lv_ChnlCustId // 피보험자채널고객ID
  //           ptcpChnlCustId: this.lv_ChnlCustId  // 관계인채널고객ID
  //         }]
  //       },
  //     }
  //     piCommonUtil.invoke('UF1PQL0471', param, this.fn_ResponseCnsntInfoData, null, this.fn_ResponseCnsntInfoData, this, this)
  // },
    /******************************************************************************
     * @function fn_ResponseCnsntInfoData
     * @notice   지금정보동의 조회 Callback
     * 선심사 및 영업시스템 고도화 PJT 미반영으로 주석처리
    ******************************************************************************/  
    // fn_ResponseCnsntInfoData (returnData) {
    //   console.log('보험금지급정보 동의 responseData', returnData)
    //   let lv_vm = this
      
    //   if (returnData !== null && returnData !== '') { // 서비스 호출 결과(body)가 있을 경우
    //     // 서비스 결과 비정상인 경우
    //     if (returnData.pymBrkdnCnsntListDto.returnCode !== '00') {
    //       piCommonUtil.getFDPConfirm('s', returnData.pymBrkdnCnsntListDto.returnMsg)
    //     }
    //     if (returnData.pymBrkdnCnsntListDto.pymBrkdnCnsntList !== null && returnData.pymBrkdnCnsntListDto.pymBrkdnCnsntList !== '') {
    //       let pymBrkdnCnsntList = returnData.pymBrkdnCnsntListDto.pymBrkdnCnsntList

    //       lv_vm.lv_PymBrkdnCnsntListDto = pymBrkdnCnsntList

    //     }
    //   }
    // },
    /******************************************************************************
     * @function fn_affordanceReset
     * @notice   스크롤 위치 0아닐때, 어포던스 버튼 재활성화 안되는경우 방지
    ******************************************************************************/  
    fn_affordanceReset() {
      if(this.lv_ViewId === 'MSPCM271D' || this.lv_ViewId === 'MSPCM278D') {
        this.isShowChevron = true
      }
    },
    /******************************************************************************
     * @function fn_openMenu
     * @notice   전체메뉴 오픈
    ******************************************************************************/  
    fn_openMenu() {
      this.$BottomManager.fn_SetBottomMenuDrawerOpen()
    },
    /*********************************************************
     * @function fn_MoveCustPrpnsInfo
     * @name     추천정보_탭_이동
     * @notice   추전정보 탭으로 이동
     *           추전정보 화면 로그 적재
    *********************************************************/
    fn_MoveCustPrpnsInfo() {
      console.log("■■■■■■■ MSPCM291D ■■■■■■■");
      this.$bizUtil.insSrnLogMini("MSPCM291D");

      this.fn_CallServiceMenuSub('MSPCM291D')
    },
  }
  // ::Methods End
}
</script>
