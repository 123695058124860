/* 
* 업무구분: 고객홈 
* 화 면 명: MSPCM291D 
* 화면설명: 고객정보 - 추천정보 
* 작 성 일: 2024.06.18 
* 작 성 자: chanwoo8.park 
*/
<template>
  <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="msp sales-sys">
    <ur-box-container alignV="start" componentid="" direction="column" class="msp-cm-wrap con-area pb50">
      <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-check-list">
        <div class="full fexTy3 mb10">
          <span class="fs22rem fwb">추천정보</span>
          <mo-button class="ns-btn-round white sm" @click="$refs.refCnfPop.open()">가입보장설계</mo-button>
        </div>
        <ur-box-container alignV="start" componentid="" direction="column" class="bd-T-Ty1">
          <div class="full fexTy2 bd-b-Ty2 pt16 pb16">
            <span class="fs16rem min90">추가계약가능성</span>
            <span class="fs16rem fwb ml20">{{ lv_CustPrpnsSVO.upselGrdNm.trim() ? lv_CustPrpnsSVO.upselGrdNm : "-" }}</span>
          </div>
          <div class="bd-b-Ty2 pt16 pb16 fex-clumn">
            <div class="full fexTy2">
              <span class="fs16rem min90">추정소득</span>
              <span class="fs16rem fwb ml20 fex-clumn">
                <span>
                  {{ showAsptnIncmItvNm ? `연간 ${lv_CustPrpnsSVO.asptnIncmItvNm}` : "-" }}
                </span>
                <span class="untact-txt fwn" style="margin-top: 4px;">
                  * 추정소득은 마케팅동의 Y 고객만 제공됩니다.
                </span>
              </span>
            </div>
          </div>
          <div class="full fexTy2 bd-b-Ty2 pt16 pb16">
            <span class="fs16rem min90">추천상품</span>
            <span class="fs16rem fwb ml20 fex-clumn">
              <template v-if="showRecmPrdtNm">
                <span v-if="lv_CustPrpnsSVO.recmPrdtNm01.trim()">1순위 {{ lv_CustPrpnsSVO.recmPrdtNm01 }}</span>
                <span v-if="lv_CustPrpnsSVO.recmPrdtNm02.trim()" class="mt4">2순위 {{ lv_CustPrpnsSVO.recmPrdtNm02 }}</span>
                <span v-if="lv_CustPrpnsSVO.recmPrdtNm03.trim()" class="mt4">3순위 {{ lv_CustPrpnsSVO.recmPrdtNm03 }}</span>
              </template>
              <template v-else>
                <span>-</span>
              </template>
            </span>
          </div>
          <div v-if="upselGrdDesc" class="full fexTy1 bd-b-Ty2 pt16 pb16">
            <span class="fs16rem fwb txt-center">
              {{ lv_RsltBasSVO.custNm }} 고객님 데이터를 분석해보니
              <span v-html="upselGrdDesc" class="crTy-blue3" /><br />
              <span v-if="showAsptnIncmItvNm">
                연소득은 <span class="crTy-blue3">{{ lv_CustPrpnsSVO.asptnIncmItvNm }}</span> 
                으로 추정되네요.
              </span>
              <span v-else>
                추정소득은 마케팅 동의가 없어서 알려드릴수가 없어요. 마케팅 동의 해주시면 확인하실 수 있어요.
              </span>
              <span v-if="showRecmPrdtNm">
                <br />
                추천상품 참고하셔서 고객 터치 해보세요.
              </span>
            </span>
          </div>
        </ur-box-container>
        <!-- 소비성향 or 투자성향 값이 있는 고객만 메시지 생성 -->
        <div v-if="showCustPrpnsAlert" class="attention-tip mt10"> 
          <mo-icon icon="msp-attention" class="att-icon">msp-attention</mo-icon>
          <p class="att-txt ml5 fs16rem">
            모니모 데이터 분석 결과로 알아보는 고객성향 보러가기 <b @click="$refs.refAtpntPop.open()"><u>확인</u></b>
          </p>
        </div>
      </ur-box-container>
    </ur-box-container>

    <!-- 고객성향 보러가기 유의사항 팝업 -->
    <mo-bottom-sheet ref="refAtpntPop" class="ns-bottom-sheet ns-style3">
      <template v-slot:title>
        유의사항
      </template>
      <div class="customer-info-txt">
        <p class="ment">
          "고객성향"은 고객님의 동의정보를(카드/화재/증권) 분석한 결과로 정보 활용시 각별한 주의가 필요합니다.<br /><br />

          주의!! 고객에게 분석항목에 대해 직접 언급시 고객의 민원을 야기할 수 있습니다. 활용시 주의를 부탁드립니다.<br /><br />

          <b>화면을 조회 하시겠습니까?</b>
        </p>
      </div>
      <template v-slot:action>
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="$refs.refAtpntPop.close">
              취소
            </mo-button>
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_CnfAtpnt">
              확인
            </mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>

    <!-- 가입보장설계 팝업 -->
    <mo-bottom-sheet ref="refCnfPop" class="ns-bottom-sheet ns-style3">
      <div class="customer-info-txt">
        <p class="ment">{{ lv_RsltBasSVO.custNm }} 고객님의 가입보장설계로 이동하시겠습니까?</p>
      </div>
      <template v-slot:action>
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="$refs.refCnfPop.close">
              취소
            </mo-button>
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_MoveMSPPI700M">
              확인
            </mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>
  </ur-box-container>
</template>

<script>
export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  // 현재 화면명
  name: "MSPCM291D",
  // 현재 화면 ID
  screenId: "MSPCM291D",
  /***********************************************************************************
   * 화면 UI Property 정의
   ***********************************************************************************/
  props: {
    pCustInfo: {
      type: Object,
      default: {},
    },
  },
  /***********************************************************************************
   * 화면 전역 변수 선언 영역
   ***********************************************************************************/
  data() {
    return {
      title: "고객추천정보",

      // 고객상세인적사항 정보SVO
      lv_RsltBasSVO: {},
      // 고객성향정보SVO
      lv_CustPrpnsSVO: {},
      // 동의정보SVO
      lv_CustPrcusCnsntSVO: {},
      isCnfAtpnt: false,
    };
  },
  /***********************************************************************************
   * Computed 함수 정의 영역
   ***********************************************************************************/
  computed: {
    showAsptnIncmItvNm() {
      return this.lv_CustPrcusCnsntSVO.mktPrcusCnsntYn === 'Y' && this.lv_CustPrpnsSVO.asptnIncmItvNm.trim();
    },
    showRecmPrdtNm() {
      return this.lv_CustPrpnsSVO.recmPrdtNm01.trim()
        || this.lv_CustPrpnsSVO.recmPrdtNm02.trim() 
        || this.lv_CustPrpnsSVO.recmPrdtNm03.trim();
    },
    showCustPrpnsAlert() {
      return (this.lv_CustPrpnsSVO.prfrCnsmIndclNm01.trim() || this.lv_CustPrpnsSVO.ssfnIvtPrpnsNm.trim()) && !this.isCnfAtpnt;
    },
    upselGrdDesc() {
      if (this.lv_CustPrpnsSVO.upselGrdNm === "상") {
        return "추가계약 가능성이 아주 높아요!";
      } else if (this.lv_CustPrpnsSVO.upselGrdNm === "중") {
        return "추가계약 가능성이 높아요!";
      } else if (this.lv_CustPrpnsSVO.upselGrdNm === "하") {
        return `추가계약 가능성이 다소 낮네요.<br />
                고객님 니즈 환기 차원에서 연락한번 해보시는것 어떠세요?`;
      }
    },
  },
  /***********************************************************************************
   * watch 정의 영역                                                                 *
   ***********************************************************************************/
  watch: {
    "pCustInfo.lv_RsltBasSVO"() {
      if (!_.isEmpty(this.pCustInfo)) {
        this.lv_RsltBasSVO = this.pCustInfo.lv_RsltBasSVO;
      }
    },
    "pCustInfo.lv_CustPrpnsSVO"() {
      if (!_.isEmpty(this.pCustInfo)) {
        this.lv_CustPrpnsSVO = this.pCustInfo.lv_CustPrpnsSVO;
      }
    },
    "pCustInfo.lv_CustPrcusCnsntSVO"() {
      if (!_.isEmpty(this.pCustInfo)) {
        this.lv_CustPrcusCnsntSVO = this.pCustInfo.lv_CustPrcusCnsntSVO;
      }
    },
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역
   ***********************************************************************************/
  methods: {
    /*********************************************************
     * @function fn_CnfAtpnt
     * @notice   고객성향 유의사항 확인
     *********************************************************/
    fn_CnfAtpnt() {
      this.$refs.refAtpntPop.close();
      this.isCnfAtpnt = true;
      this.$emit("confirm");
    },
    /*********************************************************
     * @function fn_MoveMSPPI700M
     * @name     화면전환
     * @notice   보장설계 화면 전환
     *               MSPCT110M > MSPPI700M 변경(23.06.12 이민지 프로 요청사항)
     *********************************************************/
    fn_MoveMSPPI700M() {
      // cmStore 고객정보 저장
      window.vue.getStore("cmStore").dispatch("ADD", this.lv_RsltBasSVO);

      this.$router.push({ 
        name: "MSPPI700M",
        params: {
          custNm: this.lv_RsltBasSVO.custNm, // 고객명
          chnlCustId: this.lv_RsltBasSVO.chnlCustId, // 채널고객ID
        } 
      });
    },
  }, // ::Methods End
};
</script>
