/* 
* 업무구분: 고객 
* 화 면 명: MSPCM293P 
* 화면설명: 소비 관심사 
* 작 성 일: 2024.06.18 
* 작 성 자: chanwoo8.park 
*/
<template>
  <ur-page-container class="msp" title="소비 관심사" :show-title="false" type="subpage">
    <ur-box-container direction="column" alignV="start" class="msp-cm-wrap">
      <div class="ns-sliding-topbar">
        <mo-collapsing-header-view ref="headerview" headerColor="#fff" :fixedDiff="26">
          <!-- 헤더 타이틀 영역 -->
          <template #fixed="{scrollRate}">
            <mo-top-bar color="primary" :scroll-target="lv_HeaderviewScrollCompID" scroll-shadow>
              <mo-button class="btn_popup_back" @click="fn_Back"></mo-button>
              <div slot="nav" class="icon-wrapper">
                <mo-icon color="white" icon-size="36px" borderless @click="fn_Back">drawer</mo-icon>
              </div>
              <div class="ui-gnb-title__main">
                <span>{{ scrollRate > 0.5 ? "소비 관심사" : "소비 관심사" }}</span>
              </div>
            </mo-top-bar>
          </template>

          <!-- scroll 영역 -->
          <template #scroll>
            <ur-box-container alignV="start" componentid="" direction="column" class="msp-detail-lst pb0">
              <ur-box-container alignV="start" componentid="" direction="column" class="mt20">
                <ul class="lst lst--3">
                  <li class="mb0">
                    <div class="txt-center desc">
                      또래 <span class="crTy-blue3">{{ whlItem.sxdsNm }}</span> 평균보다 카드이용이
                      <span class="crTy-blue3">{{ parseInt(whlItem.dmt6AvrCardUtilzVal) > parseInt(whlItem.peerDmt6CardUtilzVal01) ? "많아요." : "적어요." }}</span>
                    </div>
                    <div id="detail_view" class="msp_detail_view--1">
                      <div class="chart-img">
                        <div id="compr_chart" class="w100 h200px chart"></div>
                      </div>
                    </div>
                  </li>
                  <li class="mb0">
                    <div v-if="moreExpntNm" class="txt-center desc mb10">
                      또래 <span class="crTy-blue3">{{ whlItem.sxdsNm }}</span> 평균보다 <span class="crTy-blue3">{{ moreExpntNm }}</span
                      >에서 지출이 많은 편이에요.
                    </div>

                    <ur-box-container alignV="start" componentid="" direction="column">
                      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column">
                        <div class="fexTy4 w100 pdg-right-5">
                          <label>영업활용업종</label>
                          <mo-switch v-model="isToggle" class="ns-switch ml10" small labeld />
                        </div>
                      </ur-box-container>

                      <ur-box-container alignV="start" componentid="" direction="column" class="mt20">
                        <div class="w100 fexTy1 fex-clumn" style="gap: 10px;">
                          <div v-for="(item, idx) of isToggle ? salesList : whlList" :key="idx" class="w100 fexTy1 fex-clumn bar-chart-wrap bdTy12">
                            <div class="full fexTy2" style="align-items: stretch !important;">
                              <span class="fexTy1 fs16rem fwb w90px txt-center">{{ item.indclNm }}</span>
                              <span class="ml10 fex-clumn">
                                <div class="fexTy1" style="gap: 5px;">
                                  <span class="w90px txtSkip txt-right">{{ lv_RsltBasSVO.custNm }}</span>
                                  <div class="bar-chart--ty3 bdTy12">
                                    <div
                                      class="fill-bar"
                                      :style="{ 
                                        width: `${item.dmt6AvrUtilzAmtRto}%`,
                                        background: parseInt(item.dmt6AvrCardUtilzVal) > parseInt(item.peerDmt6CardUtilzVal01) ? null : '#ffa500' 
                                      }"
                                    ></div>
                                  </div>
                                  <span class="w110px">{{ $commonUtil.numberWithCommas(Math.floor(item.dmt6AvrCardUtilzVal / 10000)) }}만원</span>
                                </div>
                                <div class="fexTy1 mt4" style="gap: 5px;">
                                  <span class="w90px txtSkip txt-right">또래</span>
                                  <div class="bar-chart--ty3 bdTy12">
                                    <div class="fill-bar peer" :style="{ width: `${item.peerDmt6CardUtilzAmtRto}%` }"></div>
                                  </div>
                                  <span class="w110px">{{ $commonUtil.numberWithCommas(Math.floor(item.peerDmt6CardUtilzVal01 / 10000)) }}만원</span>
                                </div>
                              </span>
                            </div>
                          </div>
                        </div>
                      </ur-box-container>
                    </ur-box-container>
                  </li>
                </ul>
              </ur-box-container>
            </ur-box-container>
          </template>
        </mo-collapsing-header-view>
      </div>
    </ur-box-container>
  </ur-page-container>
</template>

<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
require("@/assets/lib/rMateChartH5/Assets/Css/rMateChartH5.css");
require("@/assets/lib/rMateChartH5/JS/rMateChartH5.js");
require("@/assets/lib/rMateChartH5/LicenseKey/rMateChartH5License.js");
require("@/assets/lib/rMateChartH5/JS/rMateChartVar.js");

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  // 현재 화면명
  name: "MSPCM293P",
  // 현재 화면 ID
  screenId: "MSPCM293P",
  /***********************************************************************************
   * 화면 UI Property 정의
   ***********************************************************************************/
  props: {
    lv_RsltBasSVO: {
      type: Object,
      default: () => {},
    },
    whlItem: {
      type: Object,
      default: () => {},
    },
    whlList: {
      type: Array,
      default: () => [],
    },
    salesList: {
      type: Array,
      default: () => [],
    },
    moreExpntNm: {
      type: String,
      default: "",
    },
  },
  modalProps: {
    mode: "right", // 우->좌 open popup (defalut: 하->상)
  },
  /***********************************************************************************
   * 화면 전역 변수 선언 영역
   ***********************************************************************************/
  data() {
    return {
      title: "소비 관심사",
      // 스크롤 대상 컴포넌트 ID
      lv_HeaderviewScrollCompID: "",

      isToggle: true, // 영업활용업종 여부
    };
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역
   ***********************************************************************************/
  created() {
    console.log("■■■■■■■ MSPCM293P ■■■■■■■");
  },
  mounted() {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog("MSPCM293P");
    // 스크롤 애니메이션 instance 선언
    this.lv_HeaderviewScrollCompID = this.$refs.headerview.getScrollElementId();

    this.fn_CreateComprChart();
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역
   ***********************************************************************************/
  methods: {
    /*********************************************************
     * @function fn_CreateComprChart
     * @notice   또래비교 차트 생성
     *********************************************************/
    fn_CreateComprChart() {
      let tempChartVO = [];
      // 피어그룹
      tempChartVO.push({
        title: "또래",
        value: this.whlItem.peerDmt6CardUtilzVal01 / 10000,
        color: '<SolidColor color="#d3d9e6" />',
      });
      // 고객
      tempChartVO.push({
        title: `${this.lv_RsltBasSVO.custNm} 고객`,
        value: this.whlItem.dmt6AvrCardUtilzVal / 10000,
        color: `<SolidColor color="${parseInt(this.whlItem.dmt6AvrCardUtilzVal) > parseInt(this.whlItem.peerDmt6CardUtilzVal01) ? "#519ef6" : "#ffa500"}" />`,
      });

      this.fn_CreateCharts("compr", tempChartVO, 0.1);
    },
    /*********************************************************
     * @function fn_CreateCharts
     * @notice   차트 생성
     *********************************************************/
    fn_CreateCharts(id, data, avg = 0) {
      chartData = data;
      layoutStr = `<rMateChart backgroundColor="#FFFFFF" borderStyle="none">
                    <NumberFormatter id="numfmt" useThousandsSeparator="true" />
                    <Stroke id="stroke" color="#eee" weight="1" />
                    <Column2DChart showDataTips="true" selectionMode="single">
                      <horizontalAxis>
                        <CategoryAxis id="hAxis" categoryField="title" />
                      </horizontalAxis>
                      <verticalAxis>
                        <LinearAxis id="vAxis" formatter="{numfmt}" />
                      </verticalAxis>
                      <series>
                        <Column2DSeries yField="value" labelPosition="outside" outsideLabelJsFunction="fn_TSSCM292P_LabelFunc" styleName="axisLabel">
                          <showDataEffect>
                            <SeriesInterpolate />
                          </showDataEffect>
                          <fills>
                              ${data.map((v) => v.color).join(" ")}
                          </fills>
                        </Column2DSeries>
                      </series>
                      <horizontalAxisRenderers>
                        <Axis2DRenderer axis="{hAxis}" styleName="axisLabel" />
                      </horizontalAxisRenderers>
                      <verticalAxisRenderers>
                        <Axis2DRenderer axis="{vAxis}" styleName="axisLabel" />
                      </verticalAxisRenderers>
                      <annotationElements>
                        <AxisMarker>
                          <lines>
                            <AxisLine value="${avg}" stroke="{stroke}" />
                          </lines>
                        </AxisMarker>
                      </annotationElements>
                    </Column2DChart>
                    <Style>
                      .axisLabel {
                        font-size: 0.8125rem;
                      }
                    </Style>
                  </rMateChart>`;

      rMateChartH5.create(`MSPCM293P_${id}`, `${id}_chart`, "rMateOnLoadCallFunction=chartReadyHandler", "100%", "100%");
      rMateChartH5.registerTheme(rMateChartH5.themes);
    },
    /*********************************************************
     * @function fn_Back
     * @notice   뒤로가기 버튼 클릭
     *********************************************************/
    fn_Back() {
      this.$emit("onPopupClose");
    },
  }, // ::Methods End
};
</script>

<style lang="scss" scoped>
.desc {
  border: 1px solid #d3d9e6;
  border-radius: 30px;
  padding: 10px 30px;
}
.chart {
  position: relative;
  margin: auto;
  background: #f5f7fa;
}
.lst.lst--3 {
  li {
    border: none !important;
  }
}
.bar-chart-wrap {
  gap: 5px;
  padding: 10px;
  border-radius: 12px;
}
.bar-chart--ty3 {
  box-sizing: content-box;
  height: 25px;
  border-radius: 0;
  .fill-bar {
    background: #519ef6;
    height: 25px;
    border-radius: 0;
    &.peer {
      background: #bbb;
    }
  }
}
</style>
