/*
 * 업무구분 : 고객홈
 * 화 면 명 : MSPCM281D
 * 화면설명 : 활동 - 최근 설계 상품
 * 작 성 일 : 2023.02.16
 * 작 성 자 : sh.park2022
 */
<template>
  <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="msp sales-sys">
    <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="msp-cm-wrap con-area pb80">
      <div class="full fexTy3 mb10">
        <span class="fs22rem fwb">최근 설계 상품</span>
      </div>
      <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="ns-check-sum">
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__title">
              <span class="sum">총 <strong class="crTy-blue3">{{ fn_CmptdRntEntplTot }}</strong> 건</span>
            </div>
          </div>
          <mo-button class="ns-btn-round white sm" @click="fn_MoveMSPPI700M" :disabled="!fn_CmptdChkCnslt">가입설계</mo-button>
        </mo-list-item>
      </ur-box-container> 

      <ur-box-container v-if="fn_CmptdRntEntplTot > 0" alignV="start" componentid="ur_box_container_004" direction="column" class="ns-check-list list-pa2024">
        <mo-list :list-data="lv_RntEntplInfoSVO">
          <template #list-item="{item}">
            <mo-list-item>
              <div class="list-item__contents">
                <div class="list-item__contents__title">
                  <!-- 상품명 -->
                  <span class="name txtSkip" @click="fn_MoveMSPPI001M('plan', item)">{{ item.planNm }}</span>
                </div>
                <div class="list-item__contents__info">
                  <!-- 설계 일자 -->
                  <span class="crTy-bk2">{{ fn_CmptdEntplStoreYmd(item.entplStoreYmd) }}</span><em>|</em>
                  <span class="fwb crTy-blue3">
                    <!-- 월 금액 -->
                    {{ $bizUtil.numberWithCommasCurr(item.prm, item.crncyCd, 'Y', 'Y', 3) }}
                  </span>
                </div>
              </div>
            </mo-list-item>
          </template>
        </mo-list> 
      </ur-box-container>

      <!-- NoData 영역: start  -->
      <ur-box-container v-else alignV="start" componentid="ur_box_container_002" direction="column" class="ns-contract-list no-data">
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__info">
              <span>데이터가 없습니다.</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>
      <!-- NoData 영역: end  -->

    </ur-box-container>
  </ur-box-container>
</template>

<script>
import moment from 'moment'

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
  ***********************************************************************************/
  // 현재 화면명
  name: 'MSPCM281D', 
  // 현재 화면 ID
  screenId: 'MSPCM281D', 
  /***********************************************************************************
   * 화면 UI Property 정의
  ***********************************************************************************/
  props:{
    pCustInfo: {
      type: Object,
      default: {}
    }
  },
  /***********************************************************************************
   * 화면 전역 변수 선언 영역
  ***********************************************************************************/
  data () {
    return {
      title: '최근 설계 상품',
      lv_UserInfo: {}, // 사용자 정보

      lv_RsltBasSVO: {},
      lv_RntEntplInfoSVO: [],
    }
  },
  /***********************************************************************************
   * watch 정의 영역                                                                 *
  ***********************************************************************************/
  watch: {
    'pCustInfo.lv_RsltBasSVO'() {
      if (!_.isEmpty(this.pCustInfo)) {
        this.lv_RsltBasSVO = this.pCustInfo.lv_RsltBasSVO
      }
    },
    'pCustInfo.lv_RntEntplInfoSVO'() {
      //2024-01-11 최대 30개 노출로 수정(선심사 및 영업시스템 고도화 PJT)
      if (!_.isEmpty(this.pCustInfo)) {
        if(this.pCustInfo.lv_RntEntplInfoSVO.length > 30) {
          for(let i=0; i<30; i++) {
            this.lv_RntEntplInfoSVO.push(this.pCustInfo.lv_RntEntplInfoSVO[i])
          }
        } else {
          this.lv_RntEntplInfoSVO = this.pCustInfo.lv_RntEntplInfoSVO
        }
      }
      this.$emit("affordanceCntrol")
    }
  },
  computed: {
    // 본인 고객 여부
    fn_CmptdChkCnslt() {
      return this.lv_RsltBasSVO.cnsltNo === this.lv_UserInfo.userId
    },
    fn_CmptdRntEntplTot() {
      return this.lv_RntEntplInfoSVO ? this.lv_RntEntplInfoSVO.length : 0
    }
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역
  ***********************************************************************************/
  created() {
    console.log('■■■■■■■ MSPCM281D ■■■■■■■')
    this.lv_UserInfo = this.getStore('userInfo').getters.getUserInfo
  },
  mounted() {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog('MSPCM281D')
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역
  ***********************************************************************************/
  methods: {
    /*********************************************************
     * @function fn_MoveMSPPI001M
     * @name     화면전환
     * @notice   가입설계(MSPPI001M) 화면 전환
     * @param    flag : cust / plan
     *              cust: router.params 채널고객ID
     *              plan: router.params 채널고객ID
    *********************************************************/
    fn_MoveMSPPI001M (flag, planObj) {
      let pParam = {}

      if (flag === 'cust') {
        pParam = {
          zaAgtFileId: this.lv_RsltBasSVO.chnlCustId // 채널고객ID
        }
      } else {
        if (!_.isEmpty(planObj)) {
          pParam = {
            zaPlanId: planObj.planId // 설계ID
          }
        } else {
          this.$addSnackbar('설계 ID가 존재하지 않습니다.')
          return
        }
      }

      // cmStore 고객정보 저장
      window.vue.getStore('cmStore').dispatch('ADD', this.lv_RsltBasSVO)
      this.$router.push( { name: 'MSPPI001M', params: pParam } );
    },
    /*********************************************************
     * @function fn_CmptdEntplStoreYmd
     * @name     날짜_포맷팅
     * @notice   가입설계 저장 일자 포맷팅
    *********************************************************/
    fn_CmptdEntplStoreYmd (itemTmp) {
      let rtnValu = ''
      
      if (!this.$bizUtil.isEmpty(itemTmp)) {
        let itemYmd = itemTmp.substr(0,8)
        let itemTime = itemTmp.substr(8,2) + ':' + itemTmp.substr(10,2)

        rtnValu = moment(itemYmd).format('YYYY-MM-DD') + ' ' + itemTime
      }

      return rtnValu
    },
    /*********************************************************
     * @function fn_MoveMSPPI700M
     * @name     화면전환
     * @notice   보장설계 화면 전환
     *               MSPCT110M > MSPPI700M 변경(23.06.12 이민지 프로 요청사항)
    *********************************************************/
    fn_MoveMSPPI700M () { 
      // cmStore 고객정보 저장
      window.vue.getStore('cmStore').dispatch('ADD', this.lv_RsltBasSVO)

      let pParams = {
        custNm: this.lv_RsltBasSVO.custNm, // 고객명
        chnlCustId: this.lv_RsltBasSVO.chnlCustId, // 채널고객ID
      }
      // this.$router.push( { name: 'MSPCT110M', params: pParams } );  
      this.$router.push( { name: 'MSPPI700M', params: pParams } );  
    },
  } // ::Methods End
};
</script>
