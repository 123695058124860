/*
 * 업무구분 : 고객홈
 * 화 면 명 : MSPCM275D
 * 화면설명 : 고객정보 - 정보활용동의/기타정보
 * 작 성 일 : 2023.02.16
 * 작 성 자 : sh.park2022
 */
<template>
  <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="msp sales-sys">
    <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="msp-cm-wrap con-area pb50">
      <!-- Content 영역 -->
      <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-check-list">
        <div class="full fexTy3 mb10">
          <span class="fs22rem fwb">정보활용동의/기타정보</span>
        </div>
        <!-- 필수컨설팅 -->
        <div class="list-item w100 pa2024 bd-b-Ty10">
          <div class="list-item__contents">
            <div class="list-item__contents__title">
              <span class="name txtSkip fwm fs18rem">필수컨설팅</span>
              <mo-button class="ns-btn-round white sm" @click="fn_CnfmAddCust()" :disabled="!lv_ChkCnslt">
                {{ lv_CustPrcusCnsntSVO.mndtCnstgCnsntYn === 'Y' ? '동의연장' : '동의진행'}}
              </mo-button>
            </div>
            <div class="list-item__contents__info" v-if="lv_CustPrcusCnsntSVO.mndtCnstgCnsntYn === 'Y'">
              <span class="crTy-bk1">{{ $cmUtil.frmtDateStr(lv_CustPrcusCnsntSVO.mndtCnstgCnsntEndYmd, 'yyyy-mm-dd') }} 만료</span><em>|</em>
              <span class="crTy-bk1" :class=" lv_CustPrcusCnsntSVO.mndtCnstgCnsntRmdrDds <= 30 ? 'fwb crTy-orange2' : ''">
                D - {{ lv_CustPrcusCnsntSVO.mndtCnstgCnsntRmdrDds > 99 ? '99+' : lv_CustPrcusCnsntSVO.mndtCnstgCnsntRmdrDds }}
              </span>
            </div>
            <div class="list-item__contents__info" v-else>
              <span class="crTy-bk1">없음</span>
            </div>
          </div>
        </div>

        <!-- 마케팅 -->
        <div class="list-item w100 pa2024 bd-b-Ty10">
          <div class="list-item__contents">
            <div class="list-item__contents__title">
              <span class="name txtSkip fwm fs18rem">마케팅</span>
              <mo-button class="ns-btn-round white sm" @click="fn_CnfmAddCust()" :disabled="!lv_ChkCnslt">
                {{ lv_CustPrcusCnsntSVO.mktPrcusCnsntYn === 'Y' ? '동의연장' : '동의진행'}}
              </mo-button>
            </div>
            <div class="list-item__contents__info" v-if="lv_CustPrcusCnsntSVO.mktPrcusCnsntYn === 'Y'">
              <span class="crTy-bk1">{{ $cmUtil.frmtDateStr(lv_CustPrcusCnsntSVO.mktPrcusCnsntEndYmd, 'yyyy-mm-dd') }} 만료</span><em>|</em>
              <span class="crTy-bk1" :class=" lv_CustPrcusCnsntSVO.mktPrcusCnsntRmdrDds <= 30 ? 'fwb crTy-orange2' : ''">
                D - {{ lv_CustPrcusCnsntSVO.mktPrcusCnsntRmdrDds > 99 ? '99+' : lv_CustPrcusCnsntSVO.mktPrcusCnsntRmdrDds }}
              </span>
            </div>
            <div class="list-item__contents__info" v-if="lv_CustPrcusCnsntSVO.mktPrcusCnsntYn === 'Y'">
              <span class="crTy-bk1">{{ fn_CmptdMktPsd }}</span>
            </div>
            <div class="list-item__contents__info" v-else>
              <span class="crTy-bk1">없음</span>
            </div>
          </div>
        </div>

        <!-- 두낫콜 -->
        <div class="list-item w100 pa2024 bd-b-Ty10">
          <div class="list-item__contents">
            <div class="list-item__contents__title">
              <span class="name txtSkip fwm fs18rem">두낫콜여부</span>
            </div>
            <div class="list-item__contents__info">
              <span class="fwb" :class="{'crTy-blue2':lv_CustCardInfoVO.phclPermYn === 'Y', 'crTy-orange2':lv_CustCardInfoVO.phclPermYn === 'N'}">
                {{ lv_CustCardInfoVO.phclPermYn }}
              </span>
            </div>
          </div>
        </div>

        <!-- 지급정보제공 (선심사 및 영업시스템 고도화 PJT 그랜드 오픈 대상으로 주석)-->
        <!--<div class="list-item w100 pa2024 bd-b-Ty10">
          <div class="list-item__contents">
            <div class="list-item__contents__title">
              <span class="name txtSkip fwm fs18rem">지급정보제공</span>
            </div>
            <div class="list-item__contents__info" v-if=" cnsntYn === 'Y' ">-->
              <!-- 지급정보제공 만료일 -->
              <!-- <span class="crTy-bk1" >
                {{ $bizUtil.dateDashFormat( cnsntAvlEndYmd) }} 만료
              </span><em>|</em>
              <span class="crTy-bk1" :class="(cnsntEnd <= 30 && cnsntEnd > 0) ? 'fwb crTy-orange2' : ''">
                D - {{ cnsntEnd }}
              </span>
            </div>
            <div class="list-item__contents__info" v-else>
              <span class="fwb" :class="'crTy-orange2'">
                N
              </span>
            </div>
          </div>
        </div> -->

        <!-- 적합성 -->
        <div class="list-item w100 pa2024 bd-b-Ty10">
          <div class="list-item__contents">
            <div class="list-item__contents__title">
              <span class="name txtSkip fwm fs18rem">적합성</span>
              <mo-button class="ns-btn-round white sm" @click="fn_OpenMSPPI400P()" :disabled="!lv_ChkCnslt">진단시작</mo-button>
            </div>
            <div class="list-item__contents__info">
              <!-- 적합성 만료일 -->
              <span class="crTy-bk1">
                {{ $bizUtil.dateDashFormat(lv_CustPrcusCnsntSVO.suitaCnsntEndYmd) }} 만료
              </span><em>|</em>
              <!-- 99일 초과 -->
              <span v-if="lv_CustPrcusCnsntSVO.suitaCnsntRmdrDds > 99" class="fwb crTy-orange2">
                D - 99+
              </span>
              <span v-else-if="lv_CustPrcusCnsntSVO.suitaCnsntRmdrDds > 0" class="fwb crTy-orange2">
                D - {{ lv_CustPrcusCnsntSVO.suitaCnsntRmdrDds }}
              </span>
              <span v-else class="crTy-bk1">없음</span>
            </div>
          </div>
        </div>

      </ur-box-container> 
    </ur-box-container>

  </ur-box-container>
</template>

<script>
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'

import MSPPI400P from '@/ui/pi/MSPPI400P' // 모바일 변액 적합성
import moment from 'moment'
export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
  ***********************************************************************************/
  // 현재 화면명
  name: 'MSPCM275D', 
  // 현재 화면 ID
  screenId: 'MSPCM275D', 
  /***********************************************************************************
   * 화면 UI Property 정의
  ***********************************************************************************/
  props:{
    pCustInfo: {
      type: Object,
      default: {}
    }
  },
  /***********************************************************************************
   * 화면 전역 변수 선언 영역
  ***********************************************************************************/
  data () {
    return {
      title: '정보활용동의/기타정보',
      lv_UserInfo: {}, // 사용자 정보

      lv_CustCardInfoVO: {},
      lv_CustMktPsbInfoVO: {},
      lv_CustPrcusCnsntSVO: {},
      lv_PymBrkdnCnsntListDto: [],

      //지급정보동의 만료일
      cnsntEnd: 0,
      //오늘날짜
      nowDate: moment(new Date()).format('YYYY-MM-DD').replace(/-/g,''),
      cnsntYn: 'N',
      cnsntAvlEndYmd: '',

      lv_ChkCnslt: true,
    }
  },
  /***********************************************************************************
   * watch 정의 영역                                                                 *
  ***********************************************************************************/
  watch: {
    'pCustInfo.lv_CustPrcusCnsntSVO'() {
      const lv_Vm = this

      if (!_.isEmpty(this.pCustInfo)) {
        lv_Vm.lv_CustCardInfoVO = lv_Vm.pCustInfo.lv_RsltBasSVO
        lv_Vm.lv_CustMktPsbInfoVO = lv_Vm.pCustInfo.lv_RsltBasSVO.iCCdCustMktPsbInfoVO
        lv_Vm.lv_CustPrcusCnsntSVO = lv_Vm.pCustInfo.lv_CustPrcusCnsntSVO
        
        lv_Vm.lv_ChkCnslt = lv_Vm.lv_CustCardInfoVO.cnsltNo === lv_Vm.lv_UserInfo.userId
        
        //지급정보동의제공 추가(2024-01-11 선심사 및 영업시스템 고도화 PJT)
        lv_Vm.lv_PymBrkdnCnsntListDto = lv_Vm.pCustInfo.lv_PymBrkdnCnsntListDto

        if(!_.isEmpty(lv_Vm.lv_PymBrkdnCnsntListDto)) {
          lv_Vm.cnsntYn = lv_Vm.lv_PymBrkdnCnsntListDto[0].cnsntYn
          lv_Vm.cnsntAvlEndYmd = lv_Vm.lv_PymBrkdnCnsntListDto[0].cnsntAvlEndYmd

          if(!_.isEmpty(lv_Vm.cnsntAvlEndYmd)) {
            lv_Vm.cnsntEnd = this.$cmUtil.intervalDate(lv_Vm.nowDate,lv_Vm.cnsntAvlEndYmd) 
          }
        }
      }
    },
  },
  computed: {
    // 본인 고객 여부
    // fn_CmptdChkCnslt() {
    //   return this.lv_CustCardInfoVO.cnsltNo === this.lv_UserInfo.userId
    // },
    // 방식목록
    fn_CmptdMktPsd() {
      let rtnValu = []

      if (_.isEmpty(this.lv_CustMktPsbInfoVO)) return
      if (this.lv_CustMktPsbInfoVO.mktCnvsMthTelYn === 'Y') {
        rtnValu.push('전화')
      }
      if (this.lv_CustMktPsbInfoVO.mktCnvsMthSmsYn === 'Y') {
        rtnValu.push('문자')
      }
      if (this.lv_CustMktPsbInfoVO.mktCnvsMthEmailYn === 'Y') {
        rtnValu.push('이메일')
      }
      if (this.lv_CustMktPsbInfoVO.mktCnvsMthPstYn === 'Y') {
        rtnValu.push('우편')
      }

      return rtnValu ? rtnValu.join(', ') : ''
    }
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역
  ***********************************************************************************/
  created() {
    console.log('■■■■■■■ MSPCM275D ■■■■■■■')
    this.lv_UserInfo = this.getStore('userInfo').getters.getUserInfo
  },
  mounted() {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog('MSPCM275D')
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역
  ***********************************************************************************/
  methods: {
    /*********************************************************
     * @function fn_CnfmAddCust
     * @name     고객등록컨펌
     * @notice   고객등록 화면 전환 컨펌 바텀시트
    *********************************************************/
    fn_CnfmAddCust () {
      const lv_Vm = this

      let alertMsg = '고객등록동의 화면으로 이동하시겠습니까?'

      let alertObj = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          title: '',
          content: alertMsg,
          title_pos_btn: '이동',
        },
        listeners: {
          onPopupConfirm: () => {
            let pParams = {
              srnId: 'MSPCM270M',
              custNm: this.lv_CustCardInfoVO.custNm
            }

            // cmStore 고객정보 저장
            window.vue.getStore('cmStore').dispatch('ADD', lv_Vm.lv_CustCardInfoVO)
            this.$router.push({name: 'MSPCM050M', params: pParams})
            this.$bottomModal.close(alertObj)
          },
          onPopupClose: () => {
            this.$bottomModal.close(alertObj)
          }
        }
      })
    },
    /*********************************************************
     * @function fn_MoveMSPPI400P
     * @name     팝업호출
     * @notice   적합성(모바일) 확인 팝업 호출 
    *********************************************************/
    fn_OpenMSPPI400P() {
      const lv_Vm = this

      console.log('con ', lv_Vm.lv_CustCardInfoVO)
      let popPI400P = this.$bottomModal.open(MSPPI400P, {
        properties: {
          custInfo: lv_Vm.lv_CustCardInfoVO,
          parentParam: { custNm: '', knb: '', age: '', chnlCustId: '', isGENT: undefined }
        },
        listeners: {
          onPopupClose: () => {
            this.$bottomModal.close(popPI400P)       
          }
        }
      },
      {
        properties: {
          noHeader: true
        }
      });

    },
  } // ::Methods End
};
</script>
