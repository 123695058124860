/*
 * 업무구분 : 고객홈
 * 화 면 명 : MSPCM287D
 * 화면설명 : 터치이력 - 캠페인
 * 작 성 일 : 2023.02.16
 * 작 성 자 : sh.park2022
 */
<template>
  <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="msp">
    <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="msp-cm-wrap" :class="{'pb80' : fn_CmptdCmpgnTot > 0}">

      <ur-box-container v-if="fn_CmptdCmpgnTot > 0" alignV="start" componentid="ur_box_container_001" direction="column" class="status-list type-expand list-pa2024 bd-b-Ty1 mb80">
        <mo-list-item v-for="(list,idx) in fn_CmptdCmpgnSVO" :key="idx"> 
          <!-- msp-expand 에 맨 첫번째 값만 펼침 일 겨우 첫번째에 expanded 넣어주세요! -->
          <msp-expand btn-area-first title-first expand-only-btn :expanded="idx<1" class="mo-list-expand" btn-area-class="fexTy3 align-item-start">
            <template #title>
              <div class="list-item__contents">
                <div class="list-item__contents__title">
                  <span class="name txtSkip fs19rem">{{ list.tuchYear }}</span>
                </div>
              </div>
            </template>
            <template #btn>
                <mo-button class="link-arrow down"></mo-button>
            </template>
            <template #content>
              <div class="full-list mt20">
                <div class="list-item__contents con-area mt10">
                  <div v-for="(item,idx2) in list.lv_CmpgnList" :key="idx2" class="item-box mt10" >
                    <!-- classType 연도별 분류 표시-->
                    <ur-box-container alignV="start" componentid="" direction="column" class="round-box pa1216"
                                      :class="{'bgcolor-1' : item.classType === 0, 'bgcolor-blue' : item.classType === 1}">
                      <div class="full fexTy5 fs16rem">
                        <b class="min60">{{ item.tuchDate }}</b>
                        <span>캠페인</span><em>|</em><span>{{ item.cmpgnType}}</span>
                      </div>
                      <div class="full mt4 fs16rem">
                        <span class="ml60 fex-clumn1">{{ item.cmpgnNm }}</span> <!--2023.04.18 'fex-clumn1'클래스 추가 //-->
                      </div>
                    </ur-box-container>
                  </div>
                </div>
              </div>
            </template>
          </msp-expand>
        </mo-list-item>
      </ur-box-container>

      <!-- NoData 영역: start  -->
      <ur-box-container v-else alignV="start" componentid="ur_box_container_002" direction="column" class="ns-contract-list no-data">
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__info">
              <span>데이터가 없습니다.</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>
      <!-- NoData 영역: end  -->

    </ur-box-container>

  </ur-box-container>
</template>

<script>
export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
  ***********************************************************************************/
  // 현재 화면명
  name: 'MSPCM287D', 
  // 현재 화면 ID
  screenId: 'MSPCM287D', 
  /***********************************************************************************
   * 화면 UI Property 정의
  ***********************************************************************************/
  props:{
    pCustInfo: {
      type: Object,
      default: {}
    }
  },
  /***********************************************************************************
   * 화면 전역 변수 선언 영역
  ***********************************************************************************/
  data () {
    return {
      title: '터치이력-캠페인',
      lv_UserInfo: {}, // 사용자 정보

      lv_CmpgnListSVO: [],
    }
  },
  /***********************************************************************************
   * watch 정의 영역                                                                 *
  ***********************************************************************************/
  watch: {
    'pCustInfo.lv_CmpgnListSVO'() {
      if (!_.isEmpty(this.pCustInfo)) {
        this.lv_CmpgnListSVO = this.pCustInfo.lv_CmpgnListSVO
      }
    }
  },
  computed: {
    // 캠페인 SVO 데이터 셋팅
    fn_CmptdCmpgnSVO() {
      const lv_Vm = this

      let rtnList = []
      if (_.isEmpty(this.lv_CmpgnListSVO)) return rtnList

      let flag = ''
      let i = 0
      this.lv_CmpgnListSVO.forEach(item => {
        let dateObj = item.cmpgnPtPtYmd ? item.cmpgnPtPtYmd.split('.') : []
        let dataYear = dateObj ? dateObj[0] : ''
        let sameYearList = rtnList.filter(item => item.tuchYear === dataYear)

        if (sameYearList.length < 1) {
          let dataList = lv_Vm.lv_CmpgnListSVO.filter(item => {
              dateObj = item.cmpgnPtPtYmd ? item.cmpgnPtPtYmd.split('.') : []

              item.tuchMonth = dateObj ? dateObj[1] : ''
              item.tuchDate = dateObj ? dateObj[1] + '-' + dateObj[2] : ''

              // classType (0: blue, 1: gary)
              if (flag === '') {
                  flag = item.tuchMonth
              } else if (flag !== item.tuchMonth) {
                  flag = item.tuchMonth
                  i++;
              }
              
              if (item.tuchMonth === flag) {
                  item.groupMonth = i
                  item.classType = i%2
              }
              
              return item.cmpgnPtPtYmd.indexOf(dataYear) > -1
            });

          rtnList.push({tuchYear: dataYear, lv_CmpgnList: dataList})
        }
      });

      return rtnList
    },
    fn_CmptdCmpgnTot() {
      return this.fn_CmptdCmpgnSVO ? this.fn_CmptdCmpgnSVO.length : 0
    }

  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역
  ***********************************************************************************/
  created() {
    console.log('■■■■■■■ MSPCM287D ■■■■■■■')
    this.lv_UserInfo = this.getStore('userInfo').getters.getUserInfo
  },
  mounted() {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog('MSPCM287D')
  },
};
</script>
