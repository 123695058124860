/*
 * 업무구분 : 고객홈
 * 화 면 명 : MSPCM289D
 * 화면설명 : 터치이력 - 지급
 * 작 성 일 : 2023.02.16
 * 작 성 자 : sh.park2022
 */
<template>
  <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="msp">
    <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="msp-cm-wrap" :class="{'pb80' : lv_CmpymListTot > 0}">

      <ur-box-container v-if="lv_CmpymListTot > 0" alignV="start" componentid="ur_box_container_001" direction="column" class="status-list type-expand list-pa2024 bd-b-Ty1 mb60">
        <mo-list-item v-for="(item,idx) in lv_CmpymListSVO" :key="idx"> 
          <!-- msp-expand 에 맨 첫번째 값만 펼침 일 겨우 첫번째에 expanded 넣어주세요! -->
          <msp-expand btn-area-first title-first expand-only-btn :expanded="idx < 1" class="mo-list-expand" btn-area-class="fexTy3 align-item-start">
            <template #title>
              <div class="list-item__contents">
                <div class="list-item__contents__title">
                  <span class="name txtSkip fs19rem">{{ $bizUtil.dateDashFormat(item.occuYmd) }}</span>
                </div>
                <div class="list-item__contents__info fexTy5 pt10 fs16rem">
                  <!-- 지급유형 -->
                  <span>{{ item.pymType }}</span><em>|</em>
                  <!-- 지급금액 -->
                  <span>{{ $bizUtil.numberWithCommasCurr(item.pymAmt, 'KRW', 'Y', 'Y', 3) }}</span>
                </div>
              </div>
            </template>
            <template #btn>
                <mo-button class="link-arrow down"></mo-button>
            </template>
            <template #content>
              <div class="full-list mt20 mbm20">
                <div class="list-item__contents gray-box pa2024">
                  <div class="list-item__contents__info fexTy5">
                    <span class="min80 crTy-bk7">상품명</span>
                    <span class="ml20 txtSkip fs16rem">{{ item.prdtNm }}</span>
                  </div>
                  <div class="list-item__contents__info fexTy5 mt4">
                    <span class="min80 crTy-bk7">계약번호</span>
                    <span class="ml20 txtSkip fs16rem">{{ item.contNo }}</span>
                  </div>
                  <div class="list-item__contents__info fexTy5 mt4">
                    <span class="min80 crTy-bk7">처리부서</span>
                    <span class="ml20 txtSkip fs16rem">{{ item.trtDeptNm }}</span>
                  </div>
                  <div class="list-item__contents__info fexTy5 mt4">
                    <span class="min80 crTy-bk7">처리자</span>
                    <span class="ml20 fs16rem">{{ item.trtNm }}</span>
                  </div>
                </div>
              </div>
            </template>
          </msp-expand>
        </mo-list-item>
      </ur-box-container> 

      <!-- NoData 영역: start  -->
      <ur-box-container v-else alignV="start" componentid="ur_box_container_002" direction="column" class="ns-contract-list no-data">
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__info">
              <span>데이터가 없습니다.</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>
      <!-- NoData 영역: end  -->

    </ur-box-container>

  </ur-box-container>
</template>

<script>
export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
  ***********************************************************************************/
  // 현재 화면명
  name: 'MSPCM289D', 
  // 현재 화면 ID
  screenId: 'MSPCM289D', 
  /***********************************************************************************
   * 화면 UI Property 정의
  ***********************************************************************************/
  props:{
    pCustInfo: {
      type: Object,
      default: {}
    }
  },
  /***********************************************************************************
   * 화면 전역 변수 선언 영역
  ***********************************************************************************/
  data () {
    return {
      title: '터치이력-지급',
      lv_UserInfo: {}, // 사용자 정보

      lv_CmpymListSVO: [],
    }
  },
  /***********************************************************************************
   * watch 정의 영역                                                                 *
  ***********************************************************************************/
  watch: {
    'pCustInfo.lv_CmpymListSVO'() {
      if (!_.isEmpty(this.pCustInfo)) {
        this.lv_CmpymListSVO = this.$bizUtil.cloneDeep(this.pCustInfo.lv_CmpymListSVO)
        
        this.lv_CmpymListSVO.forEach(item => {
          item.contNo = '*********' + item.contNo.substr(-4) // 계약번호 마스킹 처리
        });
      }
    }
  },
  computed: {
    lv_CmpymListTot() {
      return this.lv_CmpymListSVO ? this.lv_CmpymListSVO.length : 0
    }
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역
  ***********************************************************************************/
  created() {
    console.log('■■■■■■■ MSPCM289D ■■■■■■■')
    this.lv_UserInfo = this.getStore('userInfo').getters.getUserInfo
  },
  mounted() {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog('MSPCM289D')
  }
};
</script>
