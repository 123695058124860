/*
 * 업무구분 : 고객홈
 * 화 면 명 : MSPCM282D
 * 화면설명 : 활동 - 컨설팅
 * 작 성 일 : 2023.02.16
 * 작 성 자 : sh.park2022
 */
<template>
  <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="msp sales-sys">
    <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="msp-cm-wrap pb50 con-area"> 
      <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-check-list">
        <div class="full fexTy3 mb10">
          <span class="fs22rem fwb">컨설팅</span>
        </div>
        <div class="list-item w100 pa2024 bd-b-Ty10">
          <div class="list-item__contents">
            <div class="list-item__contents__title mb0">
              <span class="name txtSkip fwm fs18rem">최근 보장설계</span>
              <mo-button class="ns-btn-round white sm" @click="fn_MoveMSPPI700M()" :disabled="!fn_CmptdChkCnslt">
                {{ $bizUtil.isEmpty(fn_CmptdCovrAnlyYmd) ? '시작' : '확인' }}
              </mo-button>
            </div>
            <div class="list-item__contents__info pt10" v-if="!$bizUtil.isEmpty(fn_CmptdCovrAnlyYmd)">
              <span class="crTy-bk1">{{ $bizUtil.dateDashFormat(fn_CmptdCovrAnlyYmd) }}</span>
            </div>
          </div>
        </div><!-- //list-item --> 
        <div class="list-item w100 pa2024 bd-b-Ty10">
          <div class="list-item__contents">
            <div class="list-item__contents__title mb0">
              <span class="name txtSkip fwm fs18rem">헬스케어서비스</span>
              <mo-button class="ns-btn-round white sm" @click="fn_MoveMSPCT800M()" :disabled="!fn_CmptdChkCnslt">
                {{ $bizUtil.isEmpty(fn_CmptdLifeAnlyYmd) ? '시작' : '확인' }}
              </mo-button>
            </div>
            <div class="list-item__contents__info pt10" v-if="!$bizUtil.isEmpty(fn_CmptdLifeAnlyYmd)">
              <span class="crTy-bk1">{{ $bizUtil.dateDashFormat(fn_CmptdLifeAnlyYmd) }}</span>
            </div>
          </div>
        </div><!-- //list-item -->
      </ur-box-container>
    </ur-box-container>
  </ur-box-container>
</template>

<script>
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
  ***********************************************************************************/
  // 현재 화면명
  name: 'MSPCM282D', 
  // 현재 화면 ID
  screenId: 'MSPCM282D', 
  /***********************************************************************************
   * 화면 UI Property 정의
  ***********************************************************************************/
  props:{
    pCustInfo: {
      type: Object,
      default: {}
    }
  },
  /***********************************************************************************
   * 화면 전역 변수 선언 영역
  ***********************************************************************************/
  data () {
    return {
      title: '컨설팅',
      lv_UserInfo: {}, // 사용자 정보

      lv_RsltBasSVO: {},
      lv_CustRntEntplSVO: {},
      lv_UserInfo: {}

    }
  },
  /***********************************************************************************
   * watch 정의 영역                                                                 *
  ***********************************************************************************/
  watch: {
    'pCustInfo.lv_RsltBasSVO'() {
      if (!_.isEmpty(this.pCustInfo)) {
        this.lv_RsltBasSVO = this.pCustInfo.lv_RsltBasSVO
      }
    },
    'pCustInfo.lv_CustRntEntplSVO'() {
      if (!_.isEmpty(this.pCustInfo)) {
        this.lv_CustRntEntplSVO = this.pCustInfo.lv_CustRntEntplSVO
      }
    }
  },
  computed: {
    // 본인 고객 여부
    fn_CmptdChkCnslt() {
      return this.lv_RsltBasSVO.cnsltNo === this.lv_UserInfo.userId
    },
    // 보장분석일자
    fn_CmptdCovrAnlyYmd() {
      return this.lv_CustRntEntplSVO ? this.lv_CustRntEntplSVO.covrAnlyYmd : ''
    },
    // 라이프분석일자-최근
    fn_CmptdLifeAnlyYmd() {
      return this.lv_CustRntEntplSVO ? this.lv_CustRntEntplSVO.lifeAnlyYmd : ''
    }
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역
  ***********************************************************************************/
  created() {
    console.log('■■■■■■■ MSPCM282D ■■■■■■■')
    this.lv_UserInfo = this.getStore('userInfo').getters.getUserInfo
  },
  mounted() {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog('MSPCM282D')
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역
  ***********************************************************************************/
  methods: {
    /*********************************************************
     * @function fn_MoveMSPPI700M
     * @name     화면전환
     * @notice   보장설계 화면 전환
     *               MSPCT110M > MSPPI700M 변경(23.06.12 이민지 프로 요청사항)
    *********************************************************/
    fn_MoveMSPPI700M () { 
      // cmStore 고객정보 저장
      window.vue.getStore('cmStore').dispatch('ADD', this.lv_RsltBasSVO)

      let pParams = {
        custNm: this.lv_RsltBasSVO.custNm, // 고객명
        chnlCustId: this.lv_RsltBasSVO.chnlCustId, // 채널고객ID
      }
      // this.$router.push( { name: 'MSPCT110M', params: pParams } );  
      this.$router.push( { name: 'MSPPI700M', params: pParams } );  
    },
    /*********************************************************
     * @function fn_MoveMSPCT800M
     * @name     화면전환
     * @notice   헬스케어서비스 화면 전환
    *********************************************************/
    fn_MoveMSPCT800M () {
      let pParams = {
        isExistCustYn: 'Y',
        custNm: this.lv_RsltBasSVO.custNm, // 고객명
        chnlCustId: this.lv_RsltBasSVO.chnlCustId, // 채널고객ID
        age: this.$bizUtil.getAgeFromKnb(this.lv_RsltBasSVO.knb), // 고객나이
        celno: this.lv_RsltBasSVO.celno, // 휴대번호
        custInfoVO: this.lv_RsltBasSVO // 고객정보VO
      }
      
      // cmStore 고객정보 저장
      window.vue.getStore('cmStore').dispatch('ADD', this.lv_RsltBasSVO)
      this.$router.push( { name: 'MSPCT800M', params: pParams } );  
    },
    /******************************************************************************
     * @function fn_BottomAlert
     * @notice   메시지를 BottomAlert으로 띄움
     * @param {String} alertMsg - 메시지
     * @return {none} - 반환없음
    ******************************************************************************/
    fn_BottomAlert (alertMsg) {
      let alertObj = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: true,
          content: alertMsg
        },
        listeners: {
          onPopupConfirm: () => {
            this.$bottomModal.close(alertObj)
          }
        }
      });

    },
  } // ::Methods End
};
</script>
