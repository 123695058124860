/* 
* 업무구분: 고객홈 
* 화 면 명: MSPCM292D 
* 화면설명: 고객정보 - 고객성향 
* 작 성 일: 2024.06.18 
* 작 성 자: chanwoo8.park 
*/
<template>
  <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="msp sales-sys">
    <ur-box-container alignV="start" componentid="" direction="column" class="msp-cm-wrap con-area pb50">
      <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-check-list mb40">
        <div class="full fexTy3 mb10">
          <span class="fs22rem fwb">고객성향</span>
        </div>
        <ur-box-container alignV="start" componentid="" direction="column" class="bd-T-Ty1">
           <div class="full fexTy3-1 bd-b-Ty2 pt16 pb16">
            <div class="fexTy2">
              <span class="fs16rem min90">소비 관심사</span>
              <span class="fs16rem fwb ml20 fex-clumn">
                <template v-if="showPrfrIncdl">
                  <span v-if="lv_CustPrpnsSVO.prfrCnsmIndclNm01.trim()">{{ lv_CustPrpnsSVO.prfrCnsmIndclNm01 }}</span>
                  <span v-if="lv_CustPrpnsSVO.prfrCnsmIndclNm02.trim()" class="mt4">{{ lv_CustPrpnsSVO.prfrCnsmIndclNm02 }}</span>
                  <span v-if="lv_CustPrpnsSVO.prfrCnsmIndclNm03.trim()" class="mt4">{{ lv_CustPrpnsSVO.prfrCnsmIndclNm03 }}</span>
                </template>
                <template v-else>
                  <span>-</span>
                </template>
              </span>
            </div>
            <mo-button class="ns-btn-round white sm" @click="fn_OpenMSPCM293P" :disabled="disableMoveDetailBtn">자세히보기</mo-button>
           </div>
          <div class="full fexTy2 bd-b-Ty2 pt16 pb16">
            <span class="fs16rem min90">투자성향</span>
            <span class="fs16rem fwb ml20">{{ lv_CustPrpnsSVO.ssfnIvtPrpnsNm ? lv_CustPrpnsSVO.ssfnIvtPrpnsNm : '-' }}</span>
          </div>
          <div v-if="moreExpntNm || lv_CustPrpnsSVO.ssfnIvtPrpnsNm" class="full fexTy1 bd-b-Ty2 pt16 pb16">
            <span class="fs16rem fwb txt-center">
              <p v-if="showPrfrIncdl && moreExpntNm">고객님의 소비분석결과</p>
              <p v-if="moreExpntNm">
                또래 <span class="crTy-blue3">{{ whlItem.sxdsNm }}</span>
                대비 <span class="crTy-blue3">{{ moreExpntNm }}</span>에 지출이 많은 편이에요.
              </p>
              <p v-if="lv_CustPrpnsSVO.ssfnIvtPrpnsNm">
                고객님의 투자성향은 <span class="crTy-blue3">{{ lv_CustPrpnsSVO.ssfnIvtPrpnsNm }}</span> 유형이에요.
              </p>
            </span>
          </div>
        </ur-box-container>
      </ur-box-container>
    </ur-box-container>
  </ur-box-container>
</template>

<script>
import MSPCM293P from "@/ui/cm/MSPCM293P.vue"; // 소비성향 팝업

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  // 현재 화면명
  name: "MSPCM292D",
  // 현재 화면 ID
  screenId: "MSPCM292D",
  /***********************************************************************************
   * 화면 UI Property 정의
   ***********************************************************************************/
  props: {
    pCustInfo: {
      type: Object,
      default: {},
    },
  },
  /***********************************************************************************
   * 화면 전역 변수 선언 영역
   ***********************************************************************************/
  data() {
    return {
      title: "고객성향",

      // 고객상세인적사항 정보SVO
      lv_RsltBasSVO: {},
      // 고객성향정보SVO
      lv_CustPrpnsSVO: {},
      // 인별소비금액SVO
      lv_CustPrpnsCnsmDtlSVO: {},

      whlItem: {}, // 업종 - '전체' 고객소비성향
      whlList: [], // 전체선호업종 1~3위
      salesList: [], // 영업활용업종 1~3위
    };
  },
  /***********************************************************************************
   * Computed 함수 정의 영역
   ***********************************************************************************/
  computed: {
    moreExpntNm() {
      if (!this.salesList.length) return '';

      return this.salesList.reduce((acc, cur) => {
        if (parseInt(cur.dmt6AvrCardUtilzVal) > parseInt(cur.peerDmt6CardUtilzVal01))
          acc += `${acc ? ', ' : ''}${cur.indclNm}`;
        return acc;
      }, '');
    },
    showPrfrIncdl() {
      if (_.isEmpty(this.lv_CustPrpnsSVO)) return false;
      
      return this.lv_CustPrpnsSVO.prfrCnsmIndclNm01.trim() 
        || this.lv_CustPrpnsSVO.prfrCnsmIndclNm02.trim() 
        || this.lv_CustPrpnsSVO.prfrCnsmIndclNm03.trim();
    },
    disableMoveDetailBtn() {
      return !this.showPrfrIncdl || _.isEmpty(this.lv_CustPrpnsCnsmDtlSVO);
    }
  },
  /***********************************************************************************
   * watch 정의 영역                                                                 *
   ***********************************************************************************/
  watch: {
    "pCustInfo.lv_RsltBasSVO"() {
      if (!_.isEmpty(this.pCustInfo)) {
        this.lv_RsltBasSVO = this.pCustInfo.lv_RsltBasSVO;
      }
    },
    "pCustInfo.lv_CustPrpnsSVO"() {
      if (!_.isEmpty(this.pCustInfo)) {
        this.lv_CustPrpnsSVO = this.pCustInfo.lv_CustPrpnsSVO;
      }
    },
    "pCustInfo.lv_CustPrpnsCnsmDtlSVO"() {
      if (!_.isEmpty(this.pCustInfo)) {
        this.lv_CustPrpnsCnsmDtlSVO = this.pCustInfo.lv_CustPrpnsCnsmDtlSVO;
        this.whlList = [];
        this.salesList = [];
        
        if (!this.lv_CustPrpnsCnsmDtlSVO.length) return;

        this.lv_CustPrpnsCnsmDtlSVO.forEach((item) => {
          if (item.indclNm === "전체") {
            this.whlItem = item;
          } else {
            if (['1', '2', '3'].includes(item.whlUtilzAmtRnk)) {
              this.whlList.push(item);
            }
            if (['1', '2', '3'].includes(item.salesUtilzAmtRnk)) {
              this.salesList.push(item);
            }
          }
        });
        this.whlList.sort((a, b) => parseInt(a.whlUtilzAmtRnk) - parseInt(b.whlUtilzAmtRnk));
        this.salesList.sort((a, b) => parseInt(a.salesUtilzAmtRnk) - parseInt(b.salesUtilzAmtRnk));
      }
    },
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역
   ***********************************************************************************/
  methods: {
    /*********************************************************
     * @function fn_OpenMSPCM293P
     * @notice   소비 관심사 팝업 호출
     *********************************************************/
    fn_OpenMSPCM293P() {
      const popup = this.$bottomModal.open(
        MSPCM293P,
        {
          properties: {
            lv_RsltBasSVO: this.lv_RsltBasSVO,
            whlItem: this.whlItem,
            whlList: this.whlList,
            salesList: this.salesList,
            moreExpntNm: this.moreExpntNm,
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(popup);
            },
            onPopupClose: () => {
              this.$bottomModal.close(popup);
            },
          },
        },
        {
          properties: {
            noHeader: true,
          },
        }
      );
    },
  }, // ::Methods End
};
</script>
