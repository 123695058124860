/*
 * 업무구분 : 고객홈
 * 화 면 명 : MSPCM278D
 * 화면설명 : 활동 - 최근활동
 * 작 성 일 : 2023.02.16
 * 작 성 자 : sh.park2022
 */
<template>
  <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="msp sales-sys box-shadow__line">
    <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="msp-cm-wrap pb60 pt30 con-area"> 
      <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-check-list">
        <div class="full fexTy3 mb10">
          <span class="fs22rem fwb">최근활동</span>
        </div>
        <div class="list-item w100 pa2024 bd-b-Ty10">
          <div class="list-item__contents">
            <div class="list-item__contents__title">
              <span class="name txtSkip fwm fs18rem">최근 활동</span>
            </div>
            <div class="list-item__contents__info" v-if="fn_CmptdFCTuchTot > 0">
              <span class="crTy-bk1">{{ lv_FCTuchVO.tuchYmd }} {{ lv_FCTuchVO.detaActScCdNm }}</span>
            </div>
            <div class="list-item__contents__info" v-else>
              <span class="crTy-bk1">없음</span>
            </div>
          </div>
        </div><!-- //list-item --> 
        <div class="list-item w100 pa2024 bd-b-Ty10">
          <div class="list-item__contents">
            <div class="list-item__contents__title">
              <span class="name txtSkip fwm fs18rem">고객이벤트</span>
            </div>
            <div class="list-item__contents__info overflow-scroll-x whitespace-no-wrap">
              <!-- // 항목 개수 많아질 경우 스크롤 됨 -->
              <span v-for="(item,index) in lv_MemrdChnlCustMoblVO" :key="index">
                <mo-badge class="badge-sample-badge sm lightgreen2 mr4" text="" shape="status">{{ item.evtTypCdNm }}</mo-badge>
              </span>
              <div class="list-item__contents__info" v-if="fn_CmptdCustMoblTot < 1">
                <span class="crTy-bk1">없음</span>
              </div>
            </div>
          </div>
        </div><!-- //list-item -->
        <div class="list-item w100 pa2024 bd-b-Ty10">
          <div class="list-item__contents">
            <div class="list-item__contents__title">
              <span class="name txtSkip fwm fs18rem">상령일</span>
            </div>
            <div class="list-item__contents__info">
              <!-- 상령일 -->
              <span class="crTy-bk1">
                {{ $bizUtil.dateDashFormat(lv_CustPrcusCnsntSVO.inageYmd) }}
              </span><em>|</em>
              <span class="fs14rem crTy-bk7">보험나이</span>
              <span class="ml10 crTy-bk1">{{ lv_CustCardInfoVO.insrAge }}세</span>
            </div>
          </div>
        </div><!-- //list-item -->
      </ur-box-container>
    </ur-box-container>

  </ur-box-container>
</template>

<script>
export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
  ***********************************************************************************/
  // 현재 화면명
  name: 'MSPCM278D', 
  // 현재 화면 ID
  screenId: 'MSPCM278D', 
  /***********************************************************************************
   * 화면 UI Property 정의
  ***********************************************************************************/
  props:{
    pCustInfo: {
      type: Object,
      default: {}
    }
  },
  /***********************************************************************************
   * 화면 전역 변수 선언 영역
  ***********************************************************************************/
  data () {
    return {
      title: '최근활동',
      lv_UserInfo: {}, // 사용자 정보

      lv_CustCardInfoVO: {},
      lv_FCTuchSVO: [],
      lv_FCTuchVO: [],
      lv_MemrdChnlCustMoblVO: [],
      lv_CustPrcusCnsntSVO: {}
    }
  },
  /***********************************************************************************
   * watch 정의 영역                                                                 *
  ***********************************************************************************/
  watch: {
    'pCustInfo.lv_CustCardInfoVO'() {
      if (!_.isEmpty(this.pCustInfo)) {
        this.lv_CustCardInfoVO = this.pCustInfo.lv_CustCardInfoVO
      }
    },
    'pCustInfo.lv_FCTuchSVO'() {
      if (!_.isEmpty(this.pCustInfo)) {
        this.lv_FCTuchSVO = this.pCustInfo.lv_FCTuchSVO
        this.lv_FCTuchVO = this.lv_FCTuchSVO[0] ? this.lv_FCTuchSVO[0] : []
      }
    },
    'pCustInfo.lv_MemrdChnlCustMoblVO'() {
      if (!_.isEmpty(this.pCustInfo)) {
        this.lv_MemrdChnlCustMoblVO = this.pCustInfo.lv_MemrdChnlCustMoblVO
      }
    },
    'pCustInfo.lv_CustPrcusCnsntSVO'() {
      if (!_.isEmpty(this.pCustInfo)) {
        this.lv_CustPrcusCnsntSVO = this.pCustInfo.lv_CustPrcusCnsntSVO
      }
    }
  },
  computed: {
    fn_CmptdFCTuchTot() {
      return this.lv_FCTuchSVO ? this.lv_FCTuchSVO.length : 0
    },
    fn_CmptdCustMoblTot() {
      return this.lv_MemrdChnlCustMoblVO ? this.lv_MemrdChnlCustMoblVO.length : 0
    }
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역
  ***********************************************************************************/
  created() {
    console.log('■■■■■■■ MSPCM278D ■■■■■■■')
    this.lv_UserInfo = this.getStore('userInfo').getters.getUserInfo
  },
  mounted() {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog('MSPCM278D')
  },
};
</script>
