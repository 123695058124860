/*
 * 업무구분 : 고객홈
 * 화 면 명 : MSPCM279D
 * 화면설명 : 활동- 동의현황
 * 작 성 일 : 2023.02.16
 * 작 성 자 : sh.park2022
 */
<template>
  <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="msp">
    <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="msp-cm-wrap"> 
      <!-- Contents 영역 -->
      <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-check-list">
        <!-- 필수컨설팅 -->
        <div class="list-item w100 pa2024 bd-b-Ty10">
          <div class="list-item__contents">
            <div class="list-item__contents__title">
              <span class="name txtSkip fwm fs18rem">필수컨설팅</span>
              <mo-button class="ns-btn-round white sm" :disabled="!fn_CmptdChkCnslt" @click="fn_CnfmAddCust">
                {{ lv_CustPrcusCnsntSVO.mndtCnstgCnsntYn === 'Y' ? "동의연장" : "동의" }}
              </mo-button>
            </div>
            <div class="list-item__contents__info">
              <!-- 필컨 만료일 -->
              <span class="crTy-bk1" v-if="lv_CustPrcusCnsntSVO.mndtCnstgCnsntEndYmd !== '00000000'">
                {{ $bizUtil.dateDashFormat(lv_CustPrcusCnsntSVO.mndtCnstgCnsntEndYmd) }} 만료
              </span>
              <span class="crTy-bk1" v-else>만료</span>
              <em>|</em>
              <!-- 99일 초과 -->
              <span v-if="lv_CustPrcusCnsntSVO.mndtCnstgCnsntRmdrDds > 99" class="fwb crTy-orange2">
                D - 99+
              </span>
              <span v-else-if="lv_CustPrcusCnsntSVO.mndtCnstgCnsntRmdrDds > 0" class="fwb crTy-orange2">
                D - {{ lv_CustPrcusCnsntSVO.mndtCnstgCnsntRmdrDds }}
              </span>
              <span v-else class="crTy-bk1">없음</span>
            </div>
          </div>
        </div>
        <!-- 마케팅 -->
        <div class="list-item w100 pa2024 bd-b-Ty10">
          <div class="list-item__contents">
            <div class="list-item__contents__title">
              <span class="name txtSkip fwm fs18rem">마케팅</span>
              <mo-button class="ns-btn-round white sm" :disabled="!fn_CmptdChkCnslt" @click="fn_CnfmAddCust">
                {{ lv_CustPrcusCnsntSVO.mktPrcusCnsntYn === 'Y' ? "동의연장" : "동의" }}
              </mo-button>
            </div>
            <div class="list-item__contents__info">
              <!-- 마케팅 만료일 -->
              <span class="crTy-bk1" v-if="lv_CustPrcusCnsntSVO.mktPrcusCnsntEndYmd !== '00000000'">
                {{ $bizUtil.dateDashFormat(lv_CustPrcusCnsntSVO.mktPrcusCnsntEndYmd) }} 만료
              </span>
              <span class="crTy-bk1" v-else>만료</span>
              <em>|</em>
              <!-- 99일 초과 -->
              <span v-if="lv_CustPrcusCnsntSVO.mktPrcusCnsntRmdrDds > 99" class="fwb crTy-orange2">
                D - 99+
              </span>
              <span v-else-if="lv_CustPrcusCnsntSVO.mktPrcusCnsntRmdrDds > 0" class="fwb crTy-orange2">
                D - {{ lv_CustPrcusCnsntSVO.mktPrcusCnsntRmdrDds }}
              </span>
              <span v-else class="crTy-bk1">없음</span>
            </div>
          </div>
        </div>
        <!-- 적합성 -->
        <div class="list-item w100 pa2024 bd-b-Ty10">
          <div class="list-item__contents">
            <div class="list-item__contents__title">
              <span class="name txtSkip fwm fs18rem">적합성</span>
              <mo-button class="ns-btn-round white sm" @click="fn_OpenMSPPI400P()" :disabled="!fn_CmptdChkCnslt">진단시작</mo-button>
            </div>
            <div class="list-item__contents__info">
              <!-- 적합성 만료일 -->
              <span class="crTy-bk1">
                {{ $bizUtil.dateDashFormat(lv_CustPrcusCnsntSVO.suitaCnsntEndYmd) }} 만료
              </span><em>|</em>
              <!-- 99일 초과 -->
              <span v-if="lv_CustPrcusCnsntSVO.suitaCnsntRmdrDds > 99" class="fwb crTy-orange2">
                D - 99+
              </span>
              <span v-else-if="lv_CustPrcusCnsntSVO.suitaCnsntRmdrDds > 0" class="fwb crTy-orange2">
                D - {{ lv_CustPrcusCnsntSVO.suitaCnsntRmdrDds }}
              </span>
              <span v-else class="crTy-bk1">없음</span>
            </div>
          </div>
        </div>
        
      </ur-box-container>

    </ur-box-container>
  </ur-box-container>
</template>

<script>
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'

import MSPPI400P from '@/ui/pi/MSPPI400P' // 모바일 변액 적합성
export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
  ***********************************************************************************/
  // 현재 화면명
  name: 'MSPCM279D', 
  // 현재 화면 ID
  screenId: 'MSPCM279D', 
  /***********************************************************************************
   * 화면 UI Property 정의
  ***********************************************************************************/
  props:{
    pCustInfo: {
      type: Object,
      default: {}
    }
  },
  /***********************************************************************************
   * 화면 전역 변수 선언 영역
  ***********************************************************************************/
  data () {
    return {
      title: '동의현황',
      lv_UserInfo: {}, // 사용자 정보

      lv_RsltBasSVO: {},
      lv_CustPrcusCnsntSVO: {}
    }
  },
  /***********************************************************************************
   * watch 정의 영역                                                                 *
  ***********************************************************************************/
  watch: {
    'pCustInfo.lv_RsltBasSVO'() {
      if (!_.isEmpty(this.pCustInfo)) {
        this.lv_RsltBasSVO = this.pCustInfo.lv_RsltBasSVO
      }
    },
    'pCustInfo.lv_CustPrcusCnsntSVO'() {
      if (!_.isEmpty(this.pCustInfo)) {
        this.lv_CustPrcusCnsntSVO = this.pCustInfo.lv_CustPrcusCnsntSVO
      }
    }
  },
  computed: {
    // 본인 고객 여부
    fn_CmptdChkCnslt() {
      return this.lv_RsltBasSVO.cnsltNo === this.lv_UserInfo.userId
    },
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역
  ***********************************************************************************/
  created() {
    console.log('■■■■■■■ MSPCM279D ■■■■■■■')
    this.lv_UserInfo = this.getStore('userInfo').getters.getUserInfo
  },
  mounted() {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog('MSPCM279D')
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역
  ***********************************************************************************/
  methods: {
    /*********************************************************
     * @function fn_MoveMSPPI400P
     * @name     팝업호출
     * @notice   적합성(모바일) 확인 팝업 호출 
    *********************************************************/
    fn_OpenMSPPI400P() {
      let popPI400P = this.$bottomModal.open(MSPPI400P, {
        properties: {
          custInfo: this.lv_RsltBasSVO
        },
        listeners: {
          onPopupClose: () => {
            this.$bottomModal.close(popPI400P)       
          }
        }
      },
      {
        properties: {
          noHeader: true
        }
      });

    },
    /*********************************************************
     * @function fn_CnfmAddCust
     * @name     화면전환
     * @notice   고객등록동의 진입 화면으로 전환 컨펌
    *********************************************************/
    fn_CnfmAddCust () {
      let alertMsg = '고객등록동의 화면으로 이동하시겠습니까?'

      let alertObj = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          title: '',
          content: alertMsg,
          title_pos_btn: '이동',
        },
        listeners: {
          onPopupConfirm: () => {
            // cmStore 고객정보 저장
            window.vue.getStore('cmStore').dispatch('ADD', this.lv_RsltBasSVO)
            
            this.$router.push({name: 'MSPCM050M', params: {srnId: 'MSPCM270M'}})
            this.$bottomModal.close(alertObj)
          },
          onPopupClose: () => {
            this.$bottomModal.close(alertObj)
          }
        }
      });

    },
  } // ::Methods End
};
</script>
