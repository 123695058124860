/*
 * 업무구분 : 고객홈
 * 화 면 명 : MSPCM288D
 * 화면설명 : 터치이력 - 접촉
 * 작 성 일 : 2023.02.16
 * 작 성 자 : sh.park2022
 */
<template>
  <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="msp">
    <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="msp-cm-wrap" :class="{'pb80' : lv_CmptdCntcListTot > 0}">

      <ur-box-container v-if="lv_CmptdCntcListTot > 0" alignV="start" componentid="ur_box_container_001" direction="column" class="status-list type-expand list-pa2024 bd-b-Ty1 mb70">
        <mo-list-item v-for="(item,idx) in lv_CntcListSVO" :key="idx"> 
          <!-- msp-expand 에 맨 첫번째 값만 펼침 일 겨우 첫번째에 expanded 넣어주세요! -->
          <msp-expand btn-area-first title-first expand-only-btn :expanded="idx < 1" class="mo-list-expand" btn-area-class="fexTy3 align-item-start">
            <template #title>
              <div class="list-item__contents">
                <div class="list-item__contents__title">
                  <span class="name txtSkip fs19rem">{{ $bizUtil.dateDashFormat(item.cntcYmd) }}</span>
                </div>
                <div class="list-item__contents__info fexTy5 pt10 fs16rem">
                  <span>{{ item.cntcTypScCd === '1' ? '고객' : '회사' }} > {{ item.cntcTypScCd === '2' ? '고객' : '회사' }}</span><em>|</em>
                  <span>{{ item.cntcTypScCd === '1' ? item.cntcScCd : item.cntnChnl }}</span>
                </div>
              </div>
            </template>
            <template #btn>
              <mo-button class="link-arrow down" @click="fn_SelCntcDtlInfo(item)"></mo-button>
            </template>
            <template #content>
              <div class="full-list mt20 mbm20">
                <div class="list-item__contents gray-box pa2024">
                  <div class="list-item__contents__info fexTy5 mt4">
                    <span class="min80 crTy-bk7">계약번호</span>
                    <span class="ml20 txtSkip fs16rem">{{ item.contNo }}</span>
                  </div>
                  <div class="list-item__contents__info fexTy2 mt4">
                    <span class="min80 crTy-bk7">알림정보</span>
                    <span class="ml20 fs16rem" v-html="item.rsnDtlCntnt"></span>
                  </div>
                </div>
              </div>
            </template>
          </msp-expand>
        </mo-list-item>
      </ur-box-container> 

      <!-- NoData 영역: start  -->
      <ur-box-container v-else alignV="start" componentid="ur_box_container_002" direction="column" class="ns-contract-list no-data">
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__info">
              <span>데이터가 없습니다.</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>
      <!-- NoData 영역: end  -->

    </ur-box-container>

  </ur-box-container>
</template>

<script>
import CmConstants from '@/config/constants/cmConstants'
import moment from 'moment'

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
  ***********************************************************************************/
  // 현재 화면명
  name: 'MSPCM288D', 
  // 현재 화면 ID
  screenId: 'MSPCM288D', 
  /***********************************************************************************
   * 화면 UI Property 정의
  ***********************************************************************************/
  props:{
    pCustInfo: {
      type: Object,
      default: {}
    }
  },
  /***********************************************************************************
   * 화면 전역 변수 선언 영역
  ***********************************************************************************/
  data () {
    return {
      title: '터치이력-접촉',
      lv_UserInfo: {}, // 사용자 정보
      lv_RsltBasSVO: {},
      lv_CntcListSVO: [],

      // 서버일자(오늘) 처리 전용
      lv_Today: '',
    }
  },
  /***********************************************************************************
   * watch 정의 영역                                                                 *
  ***********************************************************************************/
  watch: {
    'pCustInfo.lv_RsltBasSVO'() {
      if (!_.isEmpty(this.pCustInfo)) {
        this.lv_RsltBasSVO = this.pCustInfo.lv_RsltBasSVO
      }
    },
    'pCustInfo.lv_CntcListSVO'() {
      if (!_.isEmpty(this.pCustInfo)) {
        let cntcList = this.$bizUtil.cloneDeep(this.pCustInfo.lv_CntcListSVO)

        cntcList.forEach(item => {
          item.contNo = '*********' + item.contNo.substr(-4) // 계약번호 마스킹 처리
        })
        this.lv_CntcListSVO = cntcList.sort((a, b) => b.cntcYmd-a.cntcYmd)

        if (!_.isEmpty(cntcList)) {
          this.fn_SelCntcDtlInfo(cntcList[0])
        }
      }
    }
  },
  computed: {
    lv_CmptdCntcListTot() {
      return this.lv_CntcListSVO ? this.lv_CntcListSVO.length : 0
    }
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역
  ***********************************************************************************/
  created() {
    const lv_Vm = this

    console.log('■■■■■■■ MSPCM288D ■■■■■■■')
    this.lv_UserInfo = this.getStore('userInfo').getters.getUserInfo

    // 오늘날짜
    this.$commonUtil.getServerTime()
      .then(res => {
        lv_Vm.lv_Today = moment(res).format('YYYY-MM-DD')
      });

  },
  mounted() {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog('MSPCM288D')
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역
  ***********************************************************************************/
  methods: {
    /*********************************************************
     * @function fn_SelCntcDtlInfo
     * @name     고객접촉정보_상세조회
     * @notice   사유상세내용 셋팅한다.
    *********************************************************/
    fn_SelCntcDtlInfo (selectItem) {
      if (!this.$bizUtil.isEmpty(selectItem.rsnDtlCntnt)) return
      const lv_Vm = this

      let pParam = {
          cnsltNo: this.lv_RsltBasSVO.cnsltNo, // 컨설턴트사번
          chnlCustId: this.lv_RsltBasSVO.chnlCustId, // 채널고객ID
          custCntcId: selectItem.custCntcId, // 고객접촉ID
          cntcSno: selectItem.cntcSno, // 접촉일련번호
          occuYmd: selectItem.cntcYmd, // 접촉발생일
          strYmd: this.$cmUtil.dateCal(this.lv_Today, CmConstants.DATE_FACTOR_YEAR, -5), // 최대 오늘(영업일)부터 5년전
          endYmd: this.$cmUtil.replaceNaNChars(this.lv_Today), // 오늘(영업일)
      }

      const trnstId = 'txTSSCM31S7'
      const auth = 'S'
      this.post(lv_Vm, pParam, trnstId, auth)
        .then(res => {
          if (!_.isEmpty(res) && !_.isEmpty(res.body)) {
            lv_Vm.lv_CntcListSVO.forEach(item => {
              if (item.cntcSno === selectItem.cntcSno) {
                if (!this.$bizUtil.isEmpty(res.body.rsnDtlCntnt)) {
                  let _rsnDtlCntnt = res.body.rsnDtlCntnt // 사유상세내용
                  item.rsnDtlCntnt = this.$bizUtil.replaceAll(_rsnDtlCntnt, '\n', '<br>')
                  console.log('알림정보 >> ', item.rsnDtlCntnt)
                }
              }
            });

            lv_Vm.lv_CntcListSVO = [...lv_Vm.lv_CntcListSVO]
          }
        })
        .catch(error => {
          window.vue.error(error)
        });

    },
  } // ::Methods End
};
</script>
