/*
 * 업무구분 : 고객홈
 * 화 면 명 : MSPCM276D
 * 화면설명 : 보유계약
 * 작 성 일 : 2023.02.16
 * 작 성 자 : sh.park2022
 */
<template>
  <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="msp">
    <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="msp-cm-wrap" :class="{'pb80' : fn_CmptdContTot > 0}">
      <!-- 타이틀 영역 -->
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-sum">
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__title">
              <span class="sum">총 <strong class="crTy-blue3">{{ fn_CmptdContTot }}</strong> 건</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>

      <!-- 보유계약 리스트 영역 -->
      <ur-box-container v-if="fn_CmptdContTot > 0" alignV="start" componentid="" direction="column" class="ns-check-list list-pa2024">
        <mo-list :list-data="lv_ContList">
          <template #list-item="{item}">
            <mo-list-item>
              <div class="list-item__contents" @click="fn_MoveMSPPM931M(item)">
                <div class="list-item__contents__title">
                  <!-- 고객명 -->
                  <span class="name txtSkip">{{ item.prdtNm }}</span>

                  <!-- 계약상태코드명(뱃지) 정상/실효-->
                  <mo-badge class="badge-sample-badge sm" text="" shape="status"
                            :class="{'lightgreen2' : item.contStatCdNm === '정상', 'lightorange2' : item.contStatCdNm !== '정상'}">
                    {{ item.contStatCdNm }}
                  </mo-badge>
                </div>
                
                <div class="list-item__contents__info">
                  <!-- 계약번호 -->
                  <span class="crTy-bk2">{{ !$bizUtil.isEmpty(item.contNo) ? '************' + item.contNo.substr(-5) : '' }}</span><em>|</em>

                  <!-- 납입주기코드명, 화폐코드 -->
                  <span class="fwb crTy-blue3">
                    {{ item.padCylCdNm === '월납' ? '월' : '' }} {{ $bizUtil.numberWithCommasCurr(item.prm.amount, item.crncyCd, 'Y', 'Y', 3) }}
                  </span>
                </div>

                <!-- 계약자 | 피보험자 -->
                <div class="list-item__contents__info" :class="{'dsN' : item.contrNm === ''}" v-if="lv_CustNm !== item.contrNm">
                  <span class="fs14rem mr10">계약자</span>
                  <span class="crTy-bk1">{{ item.contrNm }}</span>
                </div>
                <div class="list-item__contents__info" :class="{'dsN' : item.insrdNm === ''}" v-if="lv_CustNm !== item.insrdNm">
                  <span class="fs14rem mr10">피보험자</span>
                  <span class="crTy-bk1">{{ item.insrdNm }}</span>
                </div>

              </div>
            </mo-list-item>
          </template>
        </mo-list> 
      </ur-box-container>

      <!-- NoData 영역: start  -->
      <ur-box-container v-else alignV="start" componentid="" direction="column" class="no-data">
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__info small-space">
              <span class="ns-ftcr-gray">
                <p class="mt10">보유계약이 없습니다.</p>
                <mo-button shape="border" class="ns-btn-round sm bdTy4 crTy-bk4" @click="fn_MoveMSPPI700M" :disabled="!btnDisable || lv_RsltBasSVO.coalCustYn === 'Y'">가입설계</mo-button>
              </span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>
      <!-- NoData 영역: end  -->

    </ur-box-container>
  </ur-box-container>
</template>

<script>
export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
  ***********************************************************************************/
  // 현재 화면명
  name: 'MSPCM276D', 
  // 현재 화면 ID
  screenId: 'MSPCM276D', 
  /***********************************************************************************
   * 화면 UI Property 정의
  ***********************************************************************************/
  props:{
    pCustInfo: {
      type: Object,
      default: {}
    }
  },
  /***********************************************************************************
   * 화면 전역 변수 선언 영역
  ***********************************************************************************/
  data () {
    return {
      title: '보유계약',
      lv_UserInfo: {}, // 사용자 정보
      
      lv_RsltBasSVO: {},
      lv_ContList: [], // 보유계약 리스트
      lv_CustNm: '',   // 고객명
      btnDisable: false
    }
  },
  /***********************************************************************************
   * watch 정의 영역                                                                 *
  ***********************************************************************************/
  watch: {
    'pCustInfo.lv_RsltBasSVO'() {
      if (!_.isEmpty(this.pCustInfo)) {
        this.lv_RsltBasSVO = this.pCustInfo.lv_RsltBasSVO
        this.lv_CustNm = this.lv_RsltBasSVO.custNm

        this.lv_UserInfo = this.getStore('userInfo').getters.getUserInfo
        // 본인 고객 여부
        this.btnDisable = this.lv_RsltBasSVO && this.lv_UserInfo ? this.lv_RsltBasSVO.cnsltNo === this.lv_UserInfo.userId : false
      }
    },
    'pCustInfo.lv_ContList'() {
      if (!_.isEmpty(this.pCustInfo)) {
        this.lv_ContList = this.pCustInfo.lv_ContList
      }
    }
  },

  computed: {
    fn_CmptdContTot() {
      return this.lv_ContList ? this.lv_ContList.length : 0
    }
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역
  ***********************************************************************************/
  create() {
    console.log('■■■■■■■ MSPCM276D ■■■■■■■')
    this.lv_UserInfo = this.getStore('userInfo').getters.getUserInfo
  },
  mounted() {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog('MSPCM276D')
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역
  ***********************************************************************************/
  methods: {
    /*********************************************************
     * @function fn_MoveMSPPI001M
     * @name     화면전환
     * @notice   가입설계 화면 전환
     * @param zaAgtFileId 채널고객ID
    *********************************************************/
    fn_MoveMSPPI001M () {
      // 가입설계
      let param = {
        zaAgtFileId: this.lv_RsltBasSVO.chnlCustId // 채널고객ID
      }
      
      // cmStore 고객정보 저장
      window.vue.getStore('cmStore').dispatch('ADD', this.lv_RsltBasSVO)
      this.$router.push( { name: 'MSPPI001M', params: param } );
    },
    /*********************************************************
     * @function fn_MoveMSPPM931M
     * @name     화면전환
     * @notice   보험계약기본사항 화면 전환
    *********************************************************/
    fn_MoveMSPPM931M (item) {
      let contInfoObj = {
        callerId: 'MSPCM270M', // 송신화면 ID
        chnlCustId: this.lv_RsltBasSVO.chnlCustId, // 채널고객ID
        cnsltNo: this.lv_RsltBasSVO.cnsltNo,       // 담당컨설턴트 사번
        contNo: item.contNo, // 계약번호
      }

      // cmStore 고객정보 저장
      window.vue.getStore('cmStore').dispatch('ADD', this.lv_RsltBasSVO)
      this.$router.push({name: 'MSPPM931M', params: contInfoObj})
    },
    /*********************************************************
     * @function fn_MoveMSPPI700M
     * @name     화면전환
     * @notice   보장설계 화면 전환
     *               MSPCT110M > MSPPI700M 변경(23.06.12 이민지 프로 요청사항)
    *********************************************************/
    fn_MoveMSPPI700M () { 
      // cmStore 고객정보 저장
      window.vue.getStore('cmStore').dispatch('ADD', this.lv_RsltBasSVO)

      let pParams = {
        custNm: this.lv_RsltBasSVO.custNm, // 고객명
        chnlCustId: this.lv_RsltBasSVO.chnlCustId, // 채널고객ID
      }
      // this.$router.push( { name: 'MSPCT110M', params: pParams } );  
      this.$router.push( { name: 'MSPPI700M', params: pParams } );  
    },
  } // ::Methods End
};
</script>
