/*
 * 업무구분 : 고객홈
 * 화 면 명 : MSPCM274D
 * 화면설명 : 고객정보 - 세대관계정보
 * 작 성 일 : 2023.02.16
 * 작 성 자 : sh.park2022
 */
<template>
  <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="msp sales-sys">
    <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="msp-cm-wrap con-area">
      <div class="full fexTy3 mb10">
        <span class="fs22rem fwb">세대관계정보</span>
        <span>
        <mo-button class="ns-btn-round white sm" @click="fn_OpenMSPCM300P" :disabled="!fn_CmptdChkCnslt">태아등재</mo-button>
        <mo-button class="ns-btn-round white sm" @click="fn_OpenMSPCM304P" :disabled="!fn_CmptdChkCnslt">세대조정</mo-button>
        </span>
      </div>

      <!-- Content 영역 -->
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-list list-pa2024 pb50 bd-T-Ty1">
        <mo-list :list-data="lv_HoshInfoListInqrVO">
          <template #list-item="{item, index}">
            <mo-list-item :class="{'bgcolor-blue1' : index < 1}">
              <div class="list-item__contents">
                <div class="list-item__contents__title mb0">
                  <span class="name txtSkip" @click="fn_SearchCustInfo(item)">{{ item.custNm }}</span>
                  <span>
                    <mo-badge class="badge-box sm purple" text="" shape="status" v-if="item.knbScCd === 'ZPER02'">외국인</mo-badge>
                    <!-- ::태그정의:: 관계(mnCustRltnCd) : 본인, 배우자, 자녀, 손자, 부모, 조부모, 친척, 기타가족 -->
                    <mo-badge class="badge-box sm lightgray2" text="" shape="status">
                      {{ fn_CustRltnNm(item.mnCustRltnCd) }}
                    </mo-badge> 
                  </span>
                </div>
                <div class="mt7" v-if="index > 0 && (item.mnCustRltnCd !== '03' || (item.custNm !== '태아' && item.knbFrno !== '000000'))">
                  <div class="list-item__contents__info">
                    <span class="min80 fs14rem">{{ item.knbScCd === 'ZPER02' ? '외국인등록번호' : '주민등록번호' }}</span>
                    <!-- 임시고객인 경우, 미확보 -->
                    <span v-if="item.chnlCustScCd === '02'"
                          class="ml20 crTy-bk1">
                      {{ item.knbFrno }}-
                      <span class="crTy-red1">미확보</span>
                    </span>
                    <span v-else class="ml20 crTy-bk1">
                      {{ $cmUtil.insertString(item.knb, 6, '-') }}
                    </span>
                  </div>
                  <div class="list-item__contents__info" v-if="!$bizUtil.isEmpty(item.lv_Celno)">
                    <span class="min80 fs14rem">휴대폰번호</span>
                    <span class="ml20 crTy-bk1">{{ item.lv_Celno }}</span>
                  </div>
                </div>
              </div>
            </mo-list-item>
          </template>
        </mo-list> 
      </ur-box-container>

    </ur-box-container>
  </ur-box-container>
</template>

<script>
import cmConstants from '~/src/config/constants/cmConstants'

import MSPCM300P from '@/ui/cm/MSPCM300P' // 고객수정 팝업
import MSPCM304P from '@/ui/cm/MSPCM304P' // 세대조정 팝업

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
  ***********************************************************************************/
  // 현재 화면명
  name: 'MSPCM274D', 
  // 현재 화면 ID
  screenId: 'MSPCM274D', 
  /***********************************************************************************
  * 화면 UI Property 정의
  ***********************************************************************************/
  props:{
    pCustInfo: {
      type: Object,
      default: {}
    }
  },
  /***********************************************************************************
   * 화면 전역 변수 선언 영역
  ***********************************************************************************/
  data () {
    return {
      title: '세대관계정보',
      lv_UserInfo: {}, // 사용자 정보

      lv_RsltBasSVO: {}, // 고객상세인적사항 정보SVO
      lv_HoshInfoListInqrVO: [], // 세대원 리스트VO
    }
  },
  /***********************************************************************************
   * watch 정의 영역                                                                 *
  ***********************************************************************************/
  watch: {
    'pCustInfo.lv_RsltBasSVO'() {
      if (!_.isEmpty(this.pCustInfo)) {
        this.lv_RsltBasSVO = this.pCustInfo.lv_RsltBasSVO
        this.lv_HoshInfoListInqrVO = [this.lv_RsltBasSVO, ...this.lv_RsltBasSVO.iCHoshInfoListInqrVO]
      }
    },
    lv_HoshInfoListInqrVO() {
      this.lv_HoshInfoListInqrVO.forEach(item => {
        if (this.$bizUtil.isEmpty(item.knbFrno)) {
          item.knbFrno = item.knb ? item.knb.substr(0,6) : ''
        }

        let celnoObj = !this.$bizUtil.isEmpty(item.celno) ? item.celno.trim().split('-') : []
        item.lv_Celno = celnoObj.length > 1 ? celnoObj[0] + '-' + celnoObj[1] + '-****' : ''
      });

    }
  },
  computed: {
    // 본인 고객 여부
    fn_CmptdChkCnslt() {
      return this.lv_RsltBasSVO.cnsltNo === this.lv_UserInfo.userId
    },
    // 주고객관계
    fn_CmptdCustRltnNm() {
      let custRltnObj = cmConstants.CUST_RLTN_CD.filter(item => {
          return item.key === this.lv_RsltBasSVO.mnCustRltnCd
        })[0];
      
      return custRltnObj ? custRltnObj.label : ''
    },
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역
  ***********************************************************************************/
  created() {
    console.log('■■■■■■■ MSPCM274D ■■■■■■■')
    this.lv_UserInfo = this.getStore('userInfo').getters.getUserInfo
  },
  mounted() {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog('MSPCM274D')
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역
  ***********************************************************************************/
  methods: {
    /*********************************************************
     * @function fn_CustRltnNm
     * @name     주고객관계코드
     * @notice   관계코드명 (데이터 랜더링)
     * @param rltnCd 관계코드
    *********************************************************/
    fn_CustRltnNm (rltnCd) {
      let rltnObj = {}
      let rltnItemList = [...cmConstants.CUST_RLTN_CD, {key: '08', label: '기타가족'}]
      
      if (!this.$bizUtil.isEmpty(rltnCd)) {
        rltnObj = rltnItemList.filter(item => {
            return item.key === rltnCd
          })[0];
      }

      return rltnObj ? rltnObj.label : ''
    },
    /*********************************************************
     * @function fn_OpenMSPCM300P
     * @name     고객카드_팝업_호출
     * @notice   고객카드 수정 팝업 Open
     *           수정시, 고객정보 재조회
    *********************************************************/
    fn_OpenMSPCM300P () {
      const lv_Vm = this

      let deltDisable = !this.fn_CmptdChkCnslt || this.lv_RsltBasSVO.coalCustYn === 'Y'

      let popObj = this.$bottomModal.open(MSPCM300P, {
        properties: {
          // 세대관계정보(MSPCM303D)로 Open
          pChnlCustId: lv_Vm.lv_RsltBasSVO.chnlCustId, // 채널고객ID
          pCnsltNo: lv_Vm.lv_RsltBasSVO.cnsltNo, // 컨설턴트사번
          viewId: 'MSPCM303D',
          pDeltDisable: deltDisable,
          pAddChild: true // 태아등재
        },
        listeners: {
          onPopupConfirm: (auth, rtnObj) => {
            this.$bottomModal.close(popObj)
            // 고객정보 - 기본정보 재조회
            this.$emit('callInit', rtnObj)
          },
          onPopupClose: () => {
            this.$bottomModal.close(popObj)
          }
        }
      },
      {
        properties: {
          noHeader: true
        }
      });

    },
    /*********************************************************
     * @function fn_OpenMSPCM304P
     * @name     세대조정_팝업_호출
     * @notice   세대조정 팝업 Open
     *           팝업 닫힌후, 고객정보 재조회
    *********************************************************/
    fn_OpenMSPCM304P () {
      const lv_Vm = this
      
      let popObj = this.$bottomModal.open(MSPCM304P, {
        properties: {
          pCustInfo: lv_Vm.lv_RsltBasSVO
        },
        listeners: {
          onPopupConfirm: (rtnObj) => {
            this.$bottomModal.close(popObj)
            // 고객정보 - 기본정보 재조회
            this.$emit('callInit', rtnObj)
          },
          onPopupClose: () => {
            this.$bottomModal.close(popObj)
          }
        }
      });

    },
    /*********************************************************
     * @function fn_SearchCustInfo
     * @name     세대원_클릭_이벤트
     * @notice   세대원 고객카드 조회
    *********************************************************/
    fn_SearchCustInfo (custItem) {
      let pParam = {
        callInit: 'Y',
        custInfo: custItem
      }
      
      this.$emit('callInit', pParam)
    }
  } // ::Methods End
};
</script>
